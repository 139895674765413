import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import 'react-tabs/style/react-tabs.css';
import AuthProvider from './utils/auth/AuthProvider';
import ApolloClientProvider from './utils/ApolloClient';
import './index.css';
import './fonts/Helvetica-Bold.ttf';
import './fonts/Helvetica-Heavy.ttf';
import './fonts/Helvetica-Roman.ttf';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloClientProvider>
        <App />
      </ApolloClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
