import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button } from 'antd';
import localforage from 'localforage';
import styles from './Header.module.scss';
import analysisIcon from '../assets/icons/Analyze_White.svg';
import logo from '../assets/icons/Purina_Fly_Monitor_Logo_White.svg';
import cameraIcon from '../assets/icons/Camera_White.svg';
import producerIcon from '../assets/icons/Producer White.svg';
import helpIcon from '../assets/icons/Help_White.svg';
import useAuth from '../utils/auth/useAuth';
import colors from '../utils/variables.module.scss';
import { AuthUserRole } from '../utils/pingfederate/pingfederate';

// This function  component is rendering the header of our app in which menus are present
const Header: React.FC = () => {
  // location is object coming from useLocation hooks of react router dom
  const location = useLocation();
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  const client = useApolloClient();

  const { logout, user } = useAuth();

  // When user click on the logo of the app then this function gets invoke
  const handleOnClickLogo = (): void => {
    navigate('/analysis');
  };

  return (
    <div className={styles.headerContainer}>
      <div
        style={{ cursor: 'pointer', backgroundColor: 'none' }}
        onClick={handleOnClickLogo}
        onKeyPress={handleOnClickLogo}
        role="button"
        tabIndex={0}
      >
        <img src={logo} style={{ marginBottom: 5 }} alt="purinaEquine" width={200} />
      </div>
      <div style={{ display: 'flex' }}>
        <div className={styles.menuList} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div
            className={
              location.pathname.includes('producers') ? styles.linkDivActive : styles.linkDiv
            }
          >
            <Link to="/producers">
              <img
                src={producerIcon}
                alt="producerIcon"
                height={20}
                width={30}
                className={styles.linkIcon}
              />
              Producers
            </Link>
          </div>
          <div
            className={
              location.pathname.includes('addImage') ? styles.linkDivActive : styles.linkDiv
            }
          >
            <Link to="/addImage">
              <img
                src={cameraIcon}
                alt="cameraIcon"
                height={20}
                width={25}
                className={styles.linkIcon}
              />
              Add Image
            </Link>
          </div>
          <div
            className={
              location.pathname.includes('analysis') ? styles.linkDivActive : styles.linkDiv
            }
          >
            <Link to="/analysis">
              <img
                src={analysisIcon}
                className={styles.linkIcon}
                alt="analysisIcon"
                height={20}
                width={20}
              />
              Analysis
            </Link>
          </div>
          <div
            style={{
              marginRight: 20,
              marginLeft: 20,
              marginBottom: 10,
              width: 2,
              height: 25,
              backgroundColor: colors.whiteColor,
            }}
          />

          <div
            className={
              location.pathname.includes('/help') ? styles.linkSmallDivActive : styles.linkSmallDiv
            }
          >
            <Link to="/help" style={{ fontSize: 14 }}>
              <img
                src={helpIcon}
                alt="helpIcon"
                height={22}
                width={22}
                style={{ marginBottom: 4, marginRight: 3 }}
              />
              Help
            </Link>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: -20 }}>
          <Button
            onClick={async (): Promise<void> => {
              // to clear localforage
              await localforage.clear();
              // reset apollo cache
              await client.resetStore();
              // Auth logout
              await logout();
            }}
            className={styles.logoutContainer}
            icon={<LogoutOutlined style={{ width: 20, height: 20 }} />}
            tabIndex={0}
            type="text"
          >
            <span style={{ fontSize: 16 }}>Logout</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
