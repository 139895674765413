import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import colors from '../utils/variables.module.scss';

const SummaryMessageComponent = () => (
  <div
    style={{
      display: 'flex',
      marginLeft: 20,
      marginRight: 10,
      marginTop: 10,
      alignItems: 'center',
      border: `1px solid ${colors.lightGrey}`,
      padding: 5,
    }}
  >
    <InfoCircleFilled style={{ color: colors.blueColor, marginRight: 10, fontSize: 16 }} />
    <span style={{ fontSize: 16 }}>
      Results summary shown here is for images that have been synced to web from mobile device.
    </span>
  </div>
);

export default SummaryMessageComponent;
