import axios from 'axios';
import dayjs from 'dayjs';

export type AuthenticationRedirectQueryParamsType = {
  code: string;
};

// stores the value of user role
export enum AuthUserRole {
  admin = 'GG_Restricted_Flymonitoring_Admin_QA',
  user = 'GG_Restricted_Flymonitoring_User_QA',
}

// The user data type of auth access token
export type AuthUserDataType = {
  firstName: string;
  lastName: string;
  role: AuthUserRole;
  ag2ag: string;
  email: string;
};

// The payload data type of access token
type AccessTokenPayloadType = {
  scope: string[];
  client_id: string;
  iss: string;
  fname: string;
  lname: string;
  role: AuthUserRole;
  ag2ag: string;
  mail: string;
  account: string;
  exp: number;
};

// Type for the data used for auth
export type AuthDataType = {
  accessToken: string;
  accessTokenExpirationDate: string;
  refreshToken: string;
  user: AuthUserDataType;
};

export type AuthDataWithoutUserType = Omit<AuthDataType, 'user'>;

const CODE_RE = /[?&]code=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const TOKEN_ENDPOINT = `${process.env.REACT_APP_PINGFEDERATE_DOMAIN || ''}/as/token.oauth2`;
const AUTHORIZATION_ENDPOINT = `${
  process.env.REACT_APP_PINGFEDERATE_DOMAIN || ''
}/as/authorization.oauth2`;

const STORAGE_KEY_PREFIX = 'pingfederate';

const SESSION_STORAGE_KEYS = {
  codeVerifier: `${STORAGE_KEY_PREFIX}_codeVerifier`,
};

export const TOKEN_STORAGE_KEY = 'pingfederate_tokens';

// https://stackoverflow.com/questions/30106476/
const decodeB64 = (input: string): string =>
  decodeURIComponent(
    atob(input)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

const urlDecodeB64 = (input: string): string =>
  decodeB64(input.replace(/_/g, '/').replace(/-/g, '+'));

const createRandomString = () => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(window.crypto.getRandomValues(new Uint8Array(43)));
  randomValues.forEach((v) => {
    random += charset[v % charset.length];
  });
  return random;
};

const getCryptoSubtle = () => {
  const { crypto } = window;
  // safari 10.x uses webkitSubtle
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  return crypto.subtle || (crypto as any).webkitSubtle;
};

const sha256 = (s: string) =>
  getCryptoSubtle().digest({ name: 'SHA-256' }, new TextEncoder().encode(s));

const urlEncodeB64 = (input: string) => {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

const bufferToBase64UrlEncoded = (input: number[] | Uint8Array): string => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))));
};

export const hasAuthParams = (searchParams = window.location.search): boolean =>
  CODE_RE.test(searchParams) || ERROR_RE.test(searchParams);

export const parseQueryResult = (queryStringArg: string): AuthenticationRedirectQueryParamsType => {
  let queryString = queryStringArg;
  if (queryString.indexOf('#') > -1) {
    queryString = queryString.substr(0, queryString.indexOf('#'));
  }

  const queryParams = queryString.split('&');

  let code = '';

  queryParams.forEach((qp) => {
    const [key, val] = qp.split('=');
    if (key === 'code') {
      code = decodeURIComponent(val);
    }
  });

  return {
    code,
  };
};

/**
 * Defines a type that handles storage to/from a storage location
 */
interface ClientStorageOptions {
  daysUntilExpire: number;
}
export type ClientStorage = {
  get(key: string): string | undefined;
  save(key: string, value: string, options?: ClientStorageOptions): void;
  remove(key: string): void;
};

/**
 * A storage protocol for marshalling data to/from session storage
 */
export const SessionStorage = {
  get(key: string) {
    if (typeof sessionStorage === 'undefined') {
      return undefined;
    }

    const value = sessionStorage.getItem(key);

    if (typeof value === 'undefined' || value === null) {
      return undefined;
    }

    return value;
  },

  save(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  },

  remove(key: string) {
    sessionStorage.removeItem(key);
  },
} as ClientStorage;

export type TokenEndpointResponse = {
  access_token: string;
  refresh_token: string;
  id_token?: string;
  expires_in: number;
};

const getAuthDataWithoutUserFromTokenEndpointResponse = (responseData: TokenEndpointResponse) => ({
  accessToken: responseData.access_token,
  refreshToken: responseData.refresh_token,
  accessTokenExpirationDate: dayjs().add(responseData.expires_in, 'seconds').toISOString(),
});

// Fetch access token from the auth code using Authorization Code flow with PKCE
export const getAccessToken = async (
  authorizationCode: string,
): Promise<AuthDataWithoutUserType> => {
  // Get the code verifier (for PKCE) from session storage
  const codeVerifier = SessionStorage.get(SESSION_STORAGE_KEYS.codeVerifier);

  if (!codeVerifier) {
    throw new Error('Code verifier not present');
  }

  if (!process.env.REACT_APP_PINGFEDERATE_CLIENT_ID) {
    throw new Error('PINGFEDERATE_CLIENT_ID not present');
  }

  // Call the token endpoint
  const formData = new URLSearchParams();
  formData.append('client_id', process.env.REACT_APP_PINGFEDERATE_CLIENT_ID);
  formData.append('grant_type', 'authorization_code');
  formData.append('redirect_uri', window.location.origin);
  formData.append('code_verifier', codeVerifier);
  formData.append('code', authorizationCode);
  const response = await axios.post<TokenEndpointResponse>(TOKEN_ENDPOINT, formData);

  // Code verifier is one time use only.
  // So delete the stored code verifier after usage
  SessionStorage.remove(SESSION_STORAGE_KEYS.codeVerifier);

  return getAuthDataWithoutUserFromTokenEndpointResponse(response.data);
};

// Call pingfederate token endpoint to get new tokens using refresh token
export const pingfederateRefreshAccessToken = async (
  refreshToken: string,
): Promise<AuthDataWithoutUserType> => {
  if (!process.env.REACT_APP_PINGFEDERATE_CLIENT_ID) {
    throw new Error('PINGFEDERATE_CLIENT_ID not present');
  }

  // Call the token endpoint
  const formData = new URLSearchParams();
  formData.append('client_id', process.env.REACT_APP_PINGFEDERATE_CLIENT_ID);
  formData.append('grant_type', 'refresh_token');
  formData.append('redirect_uri', window.location.origin);
  formData.append('refresh_token', refreshToken);

  const response = await axios.post<TokenEndpointResponse>(TOKEN_ENDPOINT, formData);

  return getAuthDataWithoutUserFromTokenEndpointResponse(response.data);
};

// Decode the access token and return user data from payload
export const decodeAccessToken = (token: string): AuthUserDataType => {
  const parts = token.split('.');
  const [header, payload, signature] = parts;

  if (parts.length !== 3 || !header || !payload || !signature) {
    throw new Error('ID token could not be decoded');
  }
  const payloadJSON = JSON.parse(urlDecodeB64(payload)) as AccessTokenPayloadType;

  return {
    ag2ag: payloadJSON.ag2ag,
    email: payloadJSON.mail,
    firstName: payloadJSON.fname,
    lastName: payloadJSON.lname,
    role: payloadJSON.role,
  };
};

// Get auth code from redirect URI and get tokens from the auth code
export const handleRedirectCallback = async (): Promise<AuthDataWithoutUserType> => {
  const url = window.location.href;

  // Get the authorization code from redirect URI called by auth server
  const queryStringFragments = url.split('?').slice(1);
  if (queryStringFragments.length === 0) {
    throw new Error('There are no query params available for parsing.');
  }
  const { code } = parseQueryResult(queryStringFragments.join(''));
  if (!code) {
    throw new Error('No code returned by auth server.');
  }

  // Get token from auth code
  const authDataWithoutUser = await getAccessToken(code);

  return authDataWithoutUser;
};

// Create the PKCE authorization URL for SSO
export const buildAuthorizeUrl = async (): Promise<string> => {
  const codeVerifier = createRandomString();
  const codeChallengeBuffer = await sha256(codeVerifier);
  const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer as Uint8Array);

  // Save the code verifier to session storage for use in redirect callback
  SessionStorage.save(SESSION_STORAGE_KEYS.codeVerifier, codeVerifier);

  return `${AUTHORIZATION_ENDPOINT}?client_id=${
    process.env.REACT_APP_PINGFEDERATE_CLIENT_ID || ''
  }&response_type=code&scope=openid&redirect_uri=${
    window.location.origin
  }&code_challenge_method=S256&code_challenge=${codeChallenge}`;
};

export default null;
