import React, { useEffect, useState } from 'react';
import { Affix, Button, Space, Checkbox as AntCheckbox, Row, Col, Collapse } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { RangeValue } from 'rc-picker/lib/interface';
import { Dayjs } from 'dayjs';
import { ApolloError, useMutation } from '@apollo/client';
import { saveAs } from 'file-saver';
import { useFieldArray, useForm } from 'react-hook-form';
import FormItemComponent from './FormItemComponent';
import InputComponent from './InputComponent';
import CheckboxGroup from './CheckboxGroup';
import Checkbox from './Checkbox';
import styles from './GenerateReport.module.scss';
import {
  AllSessionsDataForPastureType,
  ChartType,
  CheckboxFormFieldsType,
  FarmData,
  GenerateReportForm,
  PastureDataForSessionsChart,
  SessionFlyCountDistributionSummary,
} from '../utils/types';
import { addressFormat, logger, renderAvgFlyCountLegend } from '../utils/helpers';
import PastureSessionFlyCountChart from '../charts/PastureSessionFlyCountChart';
import {
  AddReportMutation,
  AddReportMutationVariables,
  GeneratePdfReportMutation,
  GeneratePdfReportMutationVariables,
} from '../graphql/graphql-types';
import useAuth from '../utils/auth/useAuth';
import SessionFlyCountDistributionSummaryTable from '../tables/SessionFlyCountDistributionSummaryTable';
import SessionFlyCountDistributionChart from '../charts/SessionFlyCountDistributionChart';
import NewSessionWiseSummaryTable from '../tables/NewSessionWiseSummaryTable';
import colors from '../utils/variables.module.scss';
import { ReactComponent as SavePdf } from '../assets/icons/Sav_PDF_Symbol.svg';
import SummaryMessageComponent from './SummaryMessageComponent';

const generatePdfReportMutation = loader('../graphql/mutations/generatePdfReportMutation.graphql');
const addReportMutation = loader('../graphql/mutations/addReportMutation.graphql');

// component props
export type GenerateReportProps = {
  // function to show/hide generate report page
  setShowAnalysisReport: React.Dispatch<React.SetStateAction<boolean>>;
  // data specific to selected producer
  producerDetails?: FarmData;
  // session fly count distribution chart data
  sessionWiseFlyCountChartData: PastureDataForSessionsChart[];
  // using id and name of each selected pasture to render legend and plot session wise line data points
  selectedPastureDetails: Record<'id' | 'name', string>[];
  // start and end date values record
  selectedDateRange?: RangeValue<Dayjs>;
  // setState function to update all pastures linked sessions data
  setAllSessionsDataOfPasture: React.Dispatch<
    React.SetStateAction<AllSessionsDataForPastureType[]>
  >;
  // whether to show analysis report
  showAnalysisReport?: boolean;
  // id's for excluded session row from session summary table
  excludedSessionIds: string[];
  // session Wise Fly Count Category Distribution table and chart data
  sessionFlyDistributionData: Array<{
    // id of pasture
    id: string;
    // name of pasture
    name: string;
    // sessions of pasture
    sessions: SessionFlyCountDistributionSummary[];
  }>;
  // session wise fly count category distribution chart type
  sessionWiseFlyCountDistributionChartType: ChartType;
  // varaibles which store all pastures basic data along with its sessions
  allSessionsDataOfPasture: Array<AllSessionsDataForPastureType>;
  // session query data loading state
  sessionDataLoading: boolean;
  // session query data error state
  sessionDataError?: ApolloError;
};

// component
const GenerateReport: React.FC<GenerateReportProps> = ({
  setShowAnalysisReport,
  producerDetails,
  sessionWiseFlyCountChartData,
  allSessionsDataOfPasture,
  sessionDataLoading,
  sessionDataError,
  selectedPastureDetails,
  selectedDateRange,
  setAllSessionsDataOfPasture,
  showAnalysisReport,
  excludedSessionIds,
  sessionFlyDistributionData,
  sessionWiseFlyCountDistributionChartType,
}) => {
  // accessing logged in user details from context
  const { user } = useAuth();
  // generate report button loading indicator state
  const [reportBtnLoader, setReportBtnLoader] = useState<boolean>(false);
  // custom data to render checkbox fields related to general information section
  const generalInfoData = producerDetails
    ? [
        { label: 'Producer Name', value: 'producerName', data: producerDetails.name },
        {
          label: 'Address',
          value: 'address',
          data: addressFormat({
            addressLine1: !producerDetails.address_line_1 ? null : producerDetails.address_line_1,
            addressLine2: !producerDetails.address_line_2 ? null : producerDetails.address_line_2,
            city: !producerDetails.city ? null : producerDetails.city,
            state: !producerDetails.state ? null : producerDetails.state,
            zip: producerDetails.zipcode,
          }),
        },
        { label: 'Manager Name', value: 'managerName', data: producerDetails.manager_name },
        { label: 'Manager Email', value: 'managerEmail', data: producerDetails.manager_email },
        { label: 'Manager Phone', value: 'managerPhone', data: producerDetails.manager_phone },
      ]
    : [];

  // const used to store default values of all comments and commentToInclude value which then pass to useForm
  const sessionWiseSummaryTableFormDefaultValue = allSessionsDataOfPasture.map((item) => ({
    id: item.id,
    sessions: item.sessions
      .filter((ele) => ele.rowAction !== 'exclude')
      .map((ele) => ({
        comment: ele.comment as string,
        commentToInclude: true,
        sessionId: ele.id,
      })),
  }));

  // creating useForm instance
  const { control, handleSubmit, setValue, watch, getValues, reset } = useForm<GenerateReportForm>({
    defaultValues: {
      reportTitle: producerDetails ? producerDetails.name : '',
      generalInfo: generalInfoData.filter(({ data }) => data !== null).map(({ value }) => value),
      hasSessionWiseChart: true,
      hasPastureWiseChart: true,
      generalComments: '',
      hasGeneralComments: true,
      hasPastureWiseSummary: true,
      hasGeneralInfo: generalInfoData.some(({ data }) => !!data),
      hasSessionWiseSummary: true,
      sessionFlyDistribution: sessionFlyDistributionData.map((ele) => ({
        table: true,
        chart: true,
        id: ele.id,
      })),
      hasSessionFlyDistribution: true,
      includeAllSessionsComment: true,
      sessionWiseSummaryTableForm: sessionWiseSummaryTableFormDefaultValue,
    },
  });

  // controls whether to include session wise fly distribution table or chart based on selected pastures
  const { fields } = useFieldArray({
    control,
    name: 'sessionFlyDistribution',
  });

  // mount logic
  useEffect(() => {
    // scrolling window to top by default
    window.scrollTo(0, 0);
  }, []);

  // mutation to get report id
  const [addReport] = useMutation<AddReportMutation, AddReportMutationVariables>(addReportMutation);

  // mutation that generates pdf report
  const [generatePdf] = useMutation<GeneratePdfReportMutation, GeneratePdfReportMutationVariables>(
    generatePdfReportMutation,
  );

  // getting currentValue of generalInfo field
  const watchGeneralInfo = watch('generalInfo');
  // get values of sessionWiseSummaryTable form  values
  const watchSessionWiseSummaryTableForm = watch('sessionWiseSummaryTableForm');

  // handling form data submission
  const onSubmit = (formData: GenerateReportForm) => {
    setReportBtnLoader(true);
    // const used to store session id of those session whose comment checkbox is unchecked
    const sessionIdOfCommentsToExclude: Array<string> = [];
    // logic to add unchecked session id to sessionIdOfCommentsToExclude
    formData.sessionWiseSummaryTableForm.forEach((ele) => {
      ele.sessions.forEach((item) => {
        if (!item.commentToInclude) {
          sessionIdOfCommentsToExclude.push(item.sessionId);
        }
      });
    });

    // const to store pasture id whose fly count category distribution table is checked so that it can be display on report
    const showFlyCountCategoryDistributionTablePastureIds: Array<string> = [];
    // const to store pasture id whose  fly count category distribution chart is checked so that it can be display on report
    const showFlyCountCategoryDistributionChartPastureIds: Array<string> = [];

    formData.sessionFlyDistribution.forEach((ele) => {
      if (ele.table) {
        showFlyCountCategoryDistributionTablePastureIds.push(ele.id);
      }
      if (ele.chart) {
        showFlyCountCategoryDistributionChartPastureIds.push(ele.id);
      }
    });

    // function trigger to generate report id
    addReport({
      variables: {
        input_data: {
          reportTitle: formData.reportTitle,
          generalInfo:
            Array.isArray(formData.generalInfo) &&
            formData.generalInfo.length > 0 &&
            formData.hasGeneralInfo
              ? generalInfoData
                  .filter(({ value }) => (formData.generalInfo as string[]).includes(value))
                  .map(({ label, data }) => ({
                    label,
                    value: data,
                  }))
              : undefined,
          pastureIds: selectedPastureDetails.map(({ id }) => id),
          startDate:
            Array.isArray(selectedDateRange) && selectedDateRange[0]
              ? selectedDateRange[0].format('YYYY-MM-DD')
              : undefined,
          endDate:
            Array.isArray(selectedDateRange) && selectedDateRange[1]
              ? selectedDateRange[1].format('YYYY-MM-DD')
              : undefined,
          generalComments: !formData.hasGeneralComments ? undefined : formData.generalComments,
          author: {
            name: user && user.firstName ? `${user.firstName} ${user.lastName || ''}` : '',
            email: user && user.email ? user.email : '',
          },
          showSessionSummaryTable: formData.hasSessionWiseSummary,
          showFlyCountLineChart: formData.hasSessionWiseChart,
          sessionsToHide: excludedSessionIds.length === 0 ? [] : excludedSessionIds,
          chartType: sessionWiseFlyCountDistributionChartType,
          sessionIdOfCommentsToExclude,
          showFlyCountCategoryDistributionTablePastureIds,
          showFlyCountCategoryDistributionChartPastureIds,
        },
      },
    })
      .then(({ data }) => {
        // checking for producerDetails is present
        if (producerDetails) {
          if (data && data.insert_report_one && data.insert_report_one.id) {
            generatePdf({
              variables: {
                reportId: data.insert_report_one.id,
                farmName: producerDetails.name,
              },
            })
              .then(({ data: pdfDetails }) => {
                if (
                  pdfDetails &&
                  pdfDetails.generatePdfReport &&
                  pdfDetails.generatePdfReport.url
                ) {
                  // saving pdf in local dir
                  fetch(pdfDetails.generatePdfReport.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      saveAs(blob);
                      setReportBtnLoader(false);
                    })
                    .catch((err) => {
                      logger(err);
                      setReportBtnLoader(false);
                    });
                }
              })
              .catch((err: ApolloError) => {
                logger(err);
                setReportBtnLoader(false);
              });
          }
        }
      })
      .catch((err: ApolloError) => {
        setReportBtnLoader(false);
        logger(err);
      });
  };

  // handles render of section with checkbox form item and section title
  const renderSection = ({
    formName,
    sectionTitle,
  }: {
    sectionTitle: string | JSX.Element;
    formName: CheckboxFormFieldsType;
  }) => (
    <Space align="center" className={styles.section}>
      <FormItemComponent customStyle={{ margin: 0, padding: 0 }}>
        <Checkbox
          name={formName}
          rhfControllerProps={{ control }}
          // onClick just to avoid collapse of panel when user click on checkbox
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </FormItemComponent>
      <p
        style={{
          margin: '2px 0px 0px 0px',
          color: colors.whiteColor,
          fontFamily: 'HelveticaBold',
          fontSize: 18,
        }}
      >
        {sectionTitle}
      </p>
    </Space>
  );

  return (
    <div style={{ marginTop: -20 }}>
      <Affix>
        <div
          style={{
            backgroundColor: 'white',
            borderBottom: '0.2px solid rgba(0,0,0,0.6)',
            paddingBottom: 5,
          }}
        >
          <div
            style={{
              margin: '0 54px',
              alignItems: 'center',
              paddingTop: 5,
            }}
            className="flexBetween producerBtnStyleContainer"
          >
            <Button
              style={{ width: 100, paddingLeft: 35 }}
              onClick={() => {
                setShowAnalysisReport(false);
                // setting  setAllSessionsDataOfPasture so that user can see updated comments on analysis screen
                setAllSessionsDataOfPasture((prevData) =>
                  prevData.map((ele) => {
                    const pastureDataObject = watchSessionWiseSummaryTableForm.find(
                      (item) => item.id === ele.id,
                    );
                    const sessionsObject = ele.sessions.map((session) => {
                      const sessionComment = pastureDataObject
                        ? pastureDataObject.sessions.find((item) => item.sessionId === session.id)
                            ?.comment
                        : null;
                      return { ...session, comment: sessionComment };
                    });
                    return { ...ele, sessions: sessionsObject };
                  }),
                );
              }}
            >
              Back
            </Button>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 22 }}>Analysis Summary</div>
              <i style={{ fontSize: 12, fontWeight: 700 }}>
                Select the items you want to include in the report
              </i>
            </div>
            <Button
              icon={<SavePdf style={{ width: 15, height: 15, marginRight: 5 }} />}
              loading={reportBtnLoader}
              disabled={reportBtnLoader}
              onClick={handleSubmit(onSubmit)}
            >
              Export Report
            </Button>
          </div>
        </div>
      </Affix>
      <div style={{ paddingBottom: 20 }}>
        <div className={styles.whiteSpace} style={{ marginTop: 10 }}>
          <Collapse
            expandIcon={(panelProps) =>
              panelProps.isActive ? (
                <UpOutlined
                  style={{
                    color: colors.whiteColor,
                    padding: 0,
                    marginTop: 5,
                  }}
                />
              ) : (
                <DownOutlined
                  style={{
                    color: colors.whiteColor,
                    padding: 0,
                    marginTop: 6,
                  }}
                />
              )
            }
            expandIconPosition="right"
            defaultActiveKey={['1', '2', '3', '4', '5', '6'].concat([
              ...sessionFlyDistributionData.map(({ id }) => `table-${id}`),
              ...sessionFlyDistributionData.map(({ id }) => `chart-${id}`),
            ])}
          >
            <Collapse.Panel
              key="1"
              header={
                <Space align="center" style={{ margin: 0 }}>
                  <FormItemComponent customStyle={{ margin: 0, padding: 0 }}>
                    <Checkbox
                      // onClick just to avoid collapse of panel when user click on checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e): void => {
                        // if checked, select all fields
                        if (e.target.checked) {
                          setValue(
                            'generalInfo',
                            generalInfoData.filter(({ data }) => !!data).map(({ value }) => value),
                          );
                        } else {
                          setValue('generalInfo', undefined);
                        }
                      }}
                      name="hasGeneralInfo"
                      rhfControllerProps={{
                        control,
                      }}
                      checked={!!(watchGeneralInfo && watchGeneralInfo.length > 0)}
                    />
                  </FormItemComponent>

                  <p
                    style={{
                      margin: '2px 0px 0px 0px',
                      color: colors.whiteColor,
                      fontFamily: 'HelveticaBold',
                      fontSize: 18,
                    }}
                  >
                    General Information
                  </p>
                </Space>
              }
            >
              <FormItemComponent
                inputColSpan={18}
                labelColSpan={0}
                customStyle={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingTop: 3,
                  marginBottom: -8,
                }}
              >
                <CheckboxGroup
                  style={{
                    width: '100%',
                    paddingTop: 0,
                  }}
                  onChange={(checkedVals): void => {
                    if (checkedVals.length === 0) {
                      setValue('hasGeneralInfo', false);
                    } else {
                      setValue('hasGeneralInfo', true);
                    }
                  }}
                  name="generalInfo"
                  rhfControllerProps={{ control }}
                >
                  <Row>
                    {producerDetails
                      ? generalInfoData.map(({ label, value, data }) => (
                          <Col key={value} span={12} style={{ paddingBottom: 8 }}>
                            <AntCheckbox disabled={!data} value={!data ? undefined : value}>
                              {label} {data ? ':' : ''}
                            </AntCheckbox>
                            {data ? <span style={{ color: '#787878' }}>{data}</span> : ''}
                          </Col>
                        ))
                      : null}
                  </Row>
                </CheckboxGroup>
              </FormItemComponent>
            </Collapse.Panel>
            <Collapse.Panel
              key="2"
              header={renderSection({
                formName: 'hasSessionWiseSummary',
                sectionTitle: 'Summary',
              })}
            >
              <Space align="center" className={styles.section} style={{ marginLeft: 20 }}>
                <Checkbox
                  rhfControllerProps={{ control }}
                  onChange={(e) => {
                    // spreading getValues so that resetting one value will not reset whole form as we just want to set sessionWiseSummaryTable form  all fields
                    //  did reset as setValue is not working with useFieldArray
                    reset({
                      ...getValues(),
                      sessionWiseSummaryTableForm: watchSessionWiseSummaryTableForm.map((ele) => ({
                        id: ele.id,
                        sessions: ele.sessions.map((item) => ({
                          ...item,
                          commentToInclude: e.target.checked,
                        })),
                      })),
                    });
                  }}
                  name="includeAllSessionsComment"
                />
                <p style={{ marginBottom: 0, fontSize: 16 }}>
                  Include all sessions comment in report
                </p>
              </Space>
              <SummaryMessageComponent />
              {/* mapping all selected pasture to show individual session wise table  */}
              {allSessionsDataOfPasture.map((ele, index) => (
                <React.Fragment key={ele.id}>
                  {Array.isArray(ele.sessions) && ele.sessions.length > 0 ? (
                    <div className="sectionHeading" key={ele.id}>
                      <h2
                        style={{
                          marginBottom: 2,
                          marginTop: 10,
                          marginLeft: 20,
                          color: colors.greenColor,
                          fontFamily: 'HelveticaBold',
                        }}
                      >
                        {ele.name}
                      </h2>
                      {/* this div is just to store id of pasture inside sessionFlyDistribution object */}
                      <div style={{ display: 'none' }}>
                        <FormItemComponent customStyle={{ margin: 0, padding: 0 }}>
                          <InputComponent
                            name={`sessionWiseSummaryTableForm.${index}.id`}
                            placeholder="id"
                            rhfControllerProps={{ control }}
                          />
                        </FormItemComponent>
                      </div>
                      <NewSessionWiseSummaryTable
                        dataLoading={sessionDataLoading}
                        dataError={sessionDataError}
                        sessionWiseSummaryTableData={ele.sessions}
                        setAllSessionsDataOfPasture={setAllSessionsDataOfPasture}
                        showAnalysisReport={showAnalysisReport}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        nestedIndex={index}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </Collapse.Panel>
            <Collapse.Panel
              key="3"
              header={renderSection({
                formName: 'hasSessionWiseChart',
                sectionTitle: 'Estimated Fly Count Distribution Over Time',
              })}
            >
              <div className={styles.whiteSpace} style={{ marginBottom: 20 }}>
                <PastureSessionFlyCountChart
                  responsiveWidth="100%"
                  sessionLineChartData={sessionWiseFlyCountChartData}
                  selectedPastureDetails={selectedPastureDetails}
                />
                {renderAvgFlyCountLegend()}
              </div>
            </Collapse.Panel>

            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                {/* this div is just to store id of pasture inside sessionFlyDistribution object */}
                <div style={{ display: 'none' }}>
                  <FormItemComponent customStyle={{ margin: 0, padding: 0 }}>
                    <InputComponent
                      name={`sessionFlyDistribution.${index}.id`}
                      placeholder="id"
                      rhfControllerProps={{ control }}
                    />
                  </FormItemComponent>
                </div>
                <Collapse.Panel
                  key={`table-${sessionFlyDistributionData[index].id}`}
                  header={renderSection({
                    formName: `sessionFlyDistribution.${index}.table` as CheckboxFormFieldsType,
                    sectionTitle: (
                      <span>
                        <span style={{ textTransform: 'uppercase' }}>
                          {sessionFlyDistributionData[index].name}
                        </span>{' '}
                        FLY COUNT CATEGORY DISTRIBUTION TABLE
                      </span>
                    ),
                  })}
                >
                  <SessionFlyCountDistributionSummaryTable
                    tableData={sessionFlyDistributionData[index].sessions}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={`chart-${sessionFlyDistributionData[index].id}`}
                  header={renderSection({
                    formName: `sessionFlyDistribution.${index}.chart` as CheckboxFormFieldsType,
                    sectionTitle: (
                      <span>
                        <span style={{ textTransform: 'uppercase' }}>
                          {sessionFlyDistributionData[index].name}
                        </span>{' '}
                        FLY COUNT CATEGORY DISTRIBUTION CHART
                      </span>
                    ),
                  })}
                >
                  <SessionFlyCountDistributionChart
                    barData={sessionFlyDistributionData[index].sessions}
                    responsiveWidth="100%"
                    chartType={sessionWiseFlyCountDistributionChartType}
                  />
                </Collapse.Panel>
              </React.Fragment>
            ))}
            <Collapse.Panel
              key="6"
              header={renderSection({
                formName: 'hasGeneralComments',
                sectionTitle: 'General Comments',
              })}
            >
              <FormItemComponent
                inputColSpan={24}
                customStyle={{ paddingBottom: 30, paddingTop: 3 }}
              >
                <InputComponent
                  customStyles={{ width: '100%', marginTop: 0 }}
                  name="generalComments"
                  placeholder="Please enter your comments here"
                  rhfControllerProps={{ control }}
                  hasTextArea
                  textAreaProps={{ rows: 4, allowClear: true }}
                />
              </FormItemComponent>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default GenerateReport;
