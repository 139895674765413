import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { Button, Select, Space, Switch, Collapse, Popconfirm } from 'antd';
import { CalendarOutlined, FilterFilled, WarningFilled } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { orderBy } from 'natural-orderby';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { useLazyQuery, useQuery } from '@apollo/client';
import { RangeValue } from 'rc-picker/lib/interface';
import { useForm } from 'react-hook-form';
import {
  AllFarmsQuery,
  AllFarmsQueryVariables,
  ImagesAggregateDataOfPastureSessionsQuery,
  ImagesAggregateDataOfPastureSessionsQueryVariables,
  PasturesOfFarmQuery,
  PasturesOfFarmQueryVariables,
} from '../graphql/graphql-types';
import WarningText from '../components/WarningText';
import InfoText from '../components/InfoText';
import {
  PastureDataForSessionsChart,
  ChartType,
  AllSessionsDataForPastureType,
  GenerateReportForm,
} from '../utils/types';
import {
  calcAvgFlyCountCategory,
  renderAvgFlyCountLegend,
  getPercentValueOfFlyCountCategory,
} from '../utils/helpers';
import PastureSessionFlyCountChart from '../charts/PastureSessionFlyCountChart';
import GenerateReport from '../components/GenerateReport';
import SessionFlyCountDistributionSummaryTable from '../tables/SessionFlyCountDistributionSummaryTable';
import SessionFlyCountDistributionChart from '../charts/SessionFlyCountDistributionChart';
import SectionWiseCard from '../components/SectionWiseCard';
import colors from '../utils/variables.module.scss';
import NewSessionWiseSummaryTable from '../tables/NewSessionWiseSummaryTable';
import SummaryMessageComponent from '../components/SummaryMessageComponent';

const allFarmsQuery = loader('../graphql/queries/allFarmsQuery.graphql');
const pasturesOfFarmQuery = loader('../graphql/queries/pasturesOfFarmQuery.graphql');
const imagesAggregateDataOfPastureSessionsQuery = loader(
  '../graphql/queries/imagesAggregateDataOfPastureSessionsQuery.graphql',
);

// defining custom behaviour for datepicker to support dayjs
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

// overall summary data csv file type
type CSVFileType = {
  // unique id for each session entry
  sessionId: string;
  // name of pasture
  'Pasture Name': string;
  // per session start date
  'Session Start Date': string;
  // total cow count
  'Cows Count'?: number | null;
  // total images taken
  'Images Taken'?: number;
  // min target images
  'Min # Images'?: number | null;
  // recommended target images
  'Target # Images'?: number | null;
  // rejected images
  'Rejected Images'?: number;
  // average fly count
  'Estimated Avg. Fly Count'?: number;
  // avg. fly count range
  'Fly Count Range'?: string;
  // avg. fly count category
  'Fly Count Category'?: string;
  // total accepted images
  'Total Accepted Images'?: number | null;
  // img excellent category percentage count
  'Excellent (%)'?: number;
  // img good category percentage count
  'Good (%)'?: number;
  // img poor category percentage count
  'Poor (%)'?: number;
  // img critical category percentage count
  'Critical (%)'?: number;
};

const chipSelectionForChartTypeCommonCssObject: React.CSSProperties = {
  width: 150,
  padding: 5,
  borderRadius: 20,
  textAlign: 'center',
  marginRight: 10,
  cursor: 'pointer',
  fontSize: 14,
};

// This is the main functional component
const NewAnalysisScreen: React.FC = () => {
  // This state stores the id of farm
  const [selectedFarmId, setSelectedFarmId] = React.useState<string | undefined>(undefined);
  // This state stores the id of selected pastures
  const [selectedPastureIds, setSelectedPastureIds] = React.useState<string[]>([]);
  // stores values of date range (start date and end date) to calculate overall summary table data
  const [overallSummaryDateRange, setOverallSummaryDateRange] = useState<
    RangeValue<Dayjs> | undefined
  >(undefined);
  // state use to determine whether to show generate report page or not( if true show generate report else show analysis screen)
  const [showAnalysisReport, setShowAnalysisReport] = useState<boolean>(false);

  // whether to hide all excluded session items from session summary table
  const [hideExcludedSessionTableData, setHideExcludedSessionTableData] = useState<boolean>();

  // state use to store array of selected pasture with there respective sessions data
  const [allSessionsDataOfPasture, setAllSessionsDataOfPasture] = useState<
    Array<AllSessionsDataForPastureType>
  >([]);

  // state to set PastureFlyCountDistributionChart it can be stack bar or simple bar
  const [
    sessionFlyCountDistributionChartType,
    setSessionFlyCountDistributionChartType,
  ] = useState<ChartType>('stackBar');

  // state to set the no image warning message
  const [showNoImageWarning, setShowNoImageWarning] = useState<boolean>(false);
  // indicates whether to show session warning message
  const [showNoSessionWarning, setShowNoSessionWarning] = useState<boolean>(false);

  // creating useForm instance this is just because we want to create nested useField array when session wise table called from generate report
  const { control } = useForm<GenerateReportForm>();

  // This is the query to fetch all farms
  const { loading, data, error } = useQuery<AllFarmsQuery, AllFarmsQueryVariables>(allFarmsQuery);

  // fetching session details for a selected pasture(s) to render table and chart data
  const [
    getSelectedPastureSessionData,
    {
      loading: selectedPastureSessionLoading,
      error: selectedPastureSessionError,
      data: pastureSessionsQueryData,
    },
  ] = useLazyQuery<
    ImagesAggregateDataOfPastureSessionsQuery,
    ImagesAggregateDataOfPastureSessionsQueryVariables
  >(imagesAggregateDataOfPastureSessionsQuery, {
    onCompleted: (queryResult) => {
      // stores the  total images count for selected pastures
      let imagesCount = 0;
      // indicates if any session is present for selected pasture
      let isSessionPresent = false;

      queryResult.pasture.forEach((i) => {
        if (Array.isArray(i.sessions) && i.sessions.length > 0) {
          isSessionPresent = true;
        }
        i.sessions.forEach((session) => {
          imagesCount +=
            session.session_all_images.aggregate && session.session_all_images.aggregate.count
              ? session.session_all_images.aggregate.count
              : 0;
        });
      });

      if (isSessionPresent === false) {
        setShowNoSessionWarning(true);
      } else {
        setShowNoSessionWarning(false);
      }
      if (imagesCount === 0) {
        setShowNoImageWarning(true);
      } else {
        setShowNoImageWarning(false);
      }

      // storing only session data related to selected pastures
      const selectedPastureSessionData: Array<AllSessionsDataForPastureType> =
        Array.isArray(queryResult.pasture) && queryResult.pasture.length > 0
          ? queryResult.pasture.map(({ sessions, name, id }) => ({
              id,
              name,
              sessions: sessions.map((entry) => ({
                ...entry,
                pastureName: name,
                pastureId: id,
                totalAcceptedImgCount:
                  entry.session_accepted_agg &&
                  entry.session_accepted_agg.aggregate &&
                  entry.session_accepted_agg.aggregate.count
                    ? entry.session_accepted_agg.aggregate.count
                    : undefined,
                excellent:
                  entry.session_excellentCategory_agg &&
                  entry.session_excellentCategory_agg.aggregate &&
                  entry.session_excellentCategory_agg.aggregate.count
                    ? entry.session_excellentCategory_agg.aggregate.count
                    : undefined,
                good:
                  entry.session_goodCategory_agg &&
                  entry.session_goodCategory_agg.aggregate &&
                  entry.session_goodCategory_agg.aggregate.count
                    ? entry.session_goodCategory_agg.aggregate.count
                    : undefined,
                poor:
                  entry.session_poorCategory_agg &&
                  entry.session_poorCategory_agg.aggregate &&
                  entry.session_poorCategory_agg.aggregate.count
                    ? entry.session_poorCategory_agg.aggregate.count
                    : undefined,
                critical:
                  entry.session_criticalCategory_agg &&
                  entry.session_criticalCategory_agg.aggregate &&
                  entry.session_criticalCategory_agg.aggregate.count
                    ? entry.session_criticalCategory_agg.aggregate.count
                    : undefined,
                rowAction: 'include',
              })),
            }))
          : [];

      // setting all sessions data of selected pasture
      setAllSessionsDataOfPasture(orderBy(selectedPastureSessionData, (ele) => ele.name));
    },
  });

  // Farm data is stored
  const farms = data?.farm;

  // obtained query result for pasture's session query
  const pastureSessionData = pastureSessionsQueryData?.pasture;

  /* fetching data of pasture from selected farm;
     if you want to execute a query in response to a different event then we use  useLazyQuery,
     so, Here we are using useLazyQuery for fetching the query after selection of farm */
  const [getPastureData, { data: pasturesData }] = useLazyQuery<
    PasturesOfFarmQuery,
    PasturesOfFarmQueryVariables
  >(pasturesOfFarmQuery);

  // Pasture data is stored
  const pastures = pasturesData?.pasture;

  // This error will display when error occur during fetching farm data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  // storing chart data for session wise fly count line chart
  const sortedSessionWiseData =
    Array.isArray(pastureSessionData) &&
    pastureSessionData.length > 0 &&
    pastureSessionData.some(({ sessions }) =>
      sessions.some(
        ({ session_all_images: { aggregate } }) =>
          !!(
            aggregate &&
            aggregate.avg &&
            (aggregate.avg.fly_count_thresh1 || aggregate.avg.fly_count_thresh1 === 0)
          ),
      ),
    )
      ? pastureSessionData
          .map(({ sessions, name, id: pastureId }) =>
            sessions
              .filter(
                ({ session_all_images: { aggregate } }) =>
                  !!(
                    aggregate &&
                    aggregate.avg &&
                    (aggregate.avg.fly_count_thresh1 || aggregate.avg.fly_count_thresh1 === 0)
                  ),
              )
              .map(({ session_all_images: { aggregate }, start_date, id }) => ({
                pastureId,
                x: start_date,
                [name]:
                  (aggregate && aggregate.avg && aggregate.avg.fly_count_thresh1) ||
                  (aggregate && aggregate.avg && aggregate.avg.fly_count_thresh1 === 0)
                    ? Math.round(aggregate.avg.fly_count_thresh1)
                    : undefined,
                id,
              })),
          )
          .flat()
          .sort((a, b) => dayjs(a.x).valueOf() - dayjs(b.x).valueOf())
          .filter((item) =>
            allSessionsDataOfPasture
              .find((pasture) => pasture.id === item.pastureId)
              ?.sessions.some(({ id, rowAction }) => id === item.id && rowAction !== 'exclude'),
          )
      : [];

  // structured data for plotting session wise fly count line chart
  const structuredSessionChartData: PastureDataForSessionsChart[] = [];
  // iterating over each entry, finding and merging pasture values with same date
  sortedSessionWiseData.forEach((item) => {
    // storing entry with same date
    const findSameDateItem = structuredSessionChartData.find(({ x }) => x === item.x);
    if (findSameDateItem) {
      // finding index for merging pasture's data point values
      const findSameDateIndex = structuredSessionChartData.findIndex(
        ({ x }) => x === findSameDateItem.x,
      );
      // merging pasture data points having same date as key 'x'
      structuredSessionChartData[findSameDateIndex] = {
        ...structuredSessionChartData[findSameDateIndex],
        ...item,
      };
    } else {
      structuredSessionChartData.push(item);
    }
  });

  // storing id, name of only selected pastures fetched from query
  const selectedPastureDetails =
    !Array.isArray(pastureSessionData) || pastureSessionData.length === 0
      ? []
      : pastureSessionData.map(({ id, name }) => ({ id, name }));

  // stores selected farm's name if present used in csv conversion and generate report
  const selectedProducerDetails =
    Array.isArray(farms) && farms.length > 0
      ? farms.find(({ id }) => id === selectedFarmId)
      : undefined;

  // handling overall summary table convert and export to csv
  const handleCsvConversion = () => {
    // stores structured table data which will store data to pass in csv
    const structuredTableData: Array<CSVFileType> = [];
    // checking if session data of selected pasture is present

    allSessionsDataOfPasture.map((ele) =>
      ele.sessions.forEach(
        ({
          start_date,
          session_all_images: { aggregate: imgAggregate },
          session_rejected_images: { aggregate: rejectedImgAggregate },
          totalAcceptedImgCount,
          excellent,
          good,
          poor,
          critical,
          id,
          rowAction,
        }) => {
          // average fly count
          const avgFlyCount: number | undefined =
            imgAggregate &&
            imgAggregate.avg &&
            (imgAggregate.avg.fly_count_thresh1 || imgAggregate.avg.fly_count_thresh1 === 0)
              ? Math.round(imgAggregate.avg.fly_count_thresh1)
              : undefined;

          if (rowAction !== 'exclude') {
            structuredTableData.push({
              sessionId: id,
              'Pasture Name': ele.name,
              'Session Start Date': dayjs(start_date).format('MM/DD/YYYY'),
              'Images Taken': imgAggregate && imgAggregate.count ? imgAggregate.count : undefined,
              'Rejected Images':
                rejectedImgAggregate && rejectedImgAggregate.count
                  ? rejectedImgAggregate.count
                  : undefined,
              'Estimated Avg. Fly Count': avgFlyCount,
              'Fly Count Category':
                avgFlyCount || avgFlyCount === 0 ? calcAvgFlyCountCategory(avgFlyCount) : undefined,
              'Total Accepted Images': totalAcceptedImgCount,
              'Excellent (%)': getPercentValueOfFlyCountCategory(excellent, totalAcceptedImgCount),
              'Good (%)': getPercentValueOfFlyCountCategory(good, totalAcceptedImgCount),
              'Poor (%)': getPercentValueOfFlyCountCategory(poor, totalAcceptedImgCount),
              'Critical (%)': getPercentValueOfFlyCountCategory(critical, totalAcceptedImgCount),
            });
          }
        },
      ),
    );

    // convert to csv format
    const csvData = Papa.unparse(structuredTableData, {
      columns: [
        'Pasture Name',
        'Session Start Date',
        'Images Taken',
        'Rejected Images',
        'Estimated Avg. Fly Count',
        'Fly Count Category',
        'Total Accepted Images',
        'Excellent (%)',
        'Good (%)',
        'Poor (%)',
        'Critical (%)',
      ],
    });
    // storing current timestamp to concat to file name
    const currTimestamp = dayjs().format('YYYYMMDDHHmmss');
    if (selectedProducerDetails) {
      // storing csv file
      const fileToExport = new File(
        [csvData],
        `report_${selectedProducerDetails.name}_${currTimestamp}.csv`,
        {
          type: 'text/csv;charset=utf-8',
        },
      );
      // using file saver lib to download file
      FileSaver.saveAs(fileToExport);
    }
  };

  // const to store all pastures session wise fly count category distribution data which then pass to generate report and can also be use to get single pasture data and
  const sessionWiseFlyCountCategoryDataForAllPasture = allSessionsDataOfPasture.map((ele) => ({
    id: ele.id,
    name: ele.name,
    sessions: ele.sessions
      .filter((item) => item.rowAction !== 'exclude')
      .map((item) => ({
        id: item.id.toString(),
        sessionName: !item.pasture_session_name ? '-' : item.pasture_session_name,
        startDate: item.start_date,
        pastureId: ele.id,
        pastureName: ele.name,
        totalAcceptedImgCount: item.totalAcceptedImgCount,
        good: getPercentValueOfFlyCountCategory(item.good, item.totalAcceptedImgCount),
        poor: getPercentValueOfFlyCountCategory(item.poor, item.totalAcceptedImgCount),
        critical: getPercentValueOfFlyCountCategory(item.critical, item.totalAcceptedImgCount),
        excellent: getPercentValueOfFlyCountCategory(item.excellent, item.totalAcceptedImgCount),
      })),
  }));

  // renders generate report component
  if (showAnalysisReport) {
    return (
      <GenerateReport
        setShowAnalysisReport={setShowAnalysisReport}
        showAnalysisReport={showAnalysisReport}
        producerDetails={selectedProducerDetails}
        sessionWiseFlyCountChartData={structuredSessionChartData}
        allSessionsDataOfPasture={allSessionsDataOfPasture}
        sessionDataLoading={selectedPastureSessionLoading}
        sessionDataError={selectedPastureSessionError}
        selectedPastureDetails={selectedPastureDetails}
        selectedDateRange={overallSummaryDateRange}
        setAllSessionsDataOfPasture={setAllSessionsDataOfPasture}
        excludedSessionIds={
          allSessionsDataOfPasture
            .map((ele) =>
              ele.sessions
                .map((item) => {
                  if (item.rowAction === 'exclude') {
                    return item.id;
                  }
                  return null;
                })
                .filter((entry) => entry !== null),
            )
            .flat(1) as Array<string>
        }
        sessionFlyDistributionData={sessionWiseFlyCountCategoryDataForAllPasture}
        sessionWiseFlyCountDistributionChartType={sessionFlyCountDistributionChartType}
      />
    );
  }

  // function to render radio button so that based on selection will render different type of graph.
  const renderChipSelectionForChartType = (
    chartTypeValue: ChartType,
    setChartTypeValue: React.Dispatch<React.SetStateAction<ChartType>>,
  ) => (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 70,
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          ...chipSelectionForChartTypeCommonCssObject,
          backgroundColor:
            chartTypeValue === 'stackBar' ? colors.greenColor : colors.inActiveChipColor,
          color: chartTypeValue === 'stackBar' ? colors.whiteColor : colors.blackColor,
        }}
        onClick={() => {
          setChartTypeValue('stackBar');
        }}
      >
        100% Stacked Bar
      </div>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        style={{
          ...chipSelectionForChartTypeCommonCssObject,
          backgroundColor:
            chartTypeValue === 'simpleBar' ? colors.greenColor : colors.inActiveChipColor,
          color: chartTypeValue === 'simpleBar' ? colors.whiteColor : colors.blackColor,
        }}
        onClick={() => {
          setChartTypeValue('simpleBar');
        }}
      >
        Simple Bar
      </div>
    </div>
  );

  // const use to store boolean value based on image under analysis condition which then use to show alert msg
  const showImgUnderAnalysisAlert = allSessionsDataOfPasture
    ? allSessionsDataOfPasture.some((item) =>
        item.sessions
          .filter((ele) => ele.rowAction !== 'exclude')
          .some((ele) => {
            if (
              ele.session_unprocessed_images.aggregate &&
              ele.session_unprocessed_images.aggregate.count
            ) {
              return ele.session_unprocessed_images.aggregate.count > 0;
            }
            return false;
          }),
      )
    : false;

  return (
    <div style={{ padding: '0px 30px 10px 30px' }}>
      {/* --------------------------- Filter section start------------------------------------ */}
      <>
        {/* ternary condition to render  farm/producer select else farm/producer warning */}
        {Array.isArray(farms) && farms.length > 0 ? (
          <div className="selectBoxContainer" style={{ display: 'flex' }}>
            <span className="selectBoxLabel">Select a Producer:</span>
            <Select
              loading={loading}
              className="selectBox"
              showSearch
              style={{ width: 400 }}
              placeholder="Select a producer to view list of pastures"
              optionFilterProp="data"
              onChange={(value: string): void => {
                if (value) {
                  // resets selected pastures when farm is changed
                  setSelectedPastureIds([]);
                  setSelectedFarmId(value);

                  // resets date range filter
                  setOverallSummaryDateRange(undefined);
                  getPastureData({ variables: { _farm_id: value } });
                  // resets session related data for selected pastures
                  getSelectedPastureSessionData({
                    variables: {
                      _pasture_ids: [],
                    },
                  });
                }
              }}
              value={selectedFarmId}
            >
              {farms.map((item) => (
                <Select.Option key={item.id} value={item.id} data={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <div className="producerBtnStyleContainer">
              <Button
                disabled={!selectedFarmId}
                style={{ marginLeft: 20, color: colors.blackColor, borderColor: colors.blackColor }}
                onClick={() => {
                  setSelectedFarmId(undefined);
                  // reset all sessions data of pasture
                  setAllSessionsDataOfPasture([]);
                  // reset  selcted pastureId
                  setSelectedPastureIds([]);
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <WarningText>
              <>
                Please add a producer in <b>Producers</b> section to begin using the app.
              </>
            </WarningText>
          </div>
        )}

        {/* ternary condition to render pasture selection and date range with warning and info text */}
        {selectedFarmId ? (
          <div>
            {Array.isArray(pastures) && pastures.length > 0 ? (
              <Collapse
                accordion
                style={{
                  marginTop: 20,
                  border: 'none',
                  boxShadow: `0px 1px 3px ${colors.greyColor}`,
                }}
                expandIconPosition="right"
                defaultActiveKey={['1']}
              >
                <Panel
                  header={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: colors.whiteColor,
                        fontFamily: 'HelveticaBold',
                        fontSize: 18,
                      }}
                    >
                      <FilterFilled style={{ fontSize: 16, marginBottom: 3 }} />
                      <div
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                        }}
                      >
                        FILTER BY
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div>
                    {Array.isArray(pastures) && pastures.length > 0 ? (
                      <>
                        <div className="selectBoxContainer analysisScreenMultipleSelect">
                          <span className="selectBoxLabel" style={{ marginRight: 80 }}>
                            Pasture:
                          </span>
                          <Select
                            loading={loading}
                            className="selectBox"
                            mode="multiple"
                            allowClear
                            disabled={!Array.isArray(pastures) || pastures.length === 0}
                            showSearch
                            style={{ width: 400 }}
                            placeholder="Select pastures to filter results"
                            optionFilterProp="data"
                            onChange={(value): void => {
                              if (Array.isArray(value) && value.length > 0) {
                                setSelectedPastureIds(value);

                                // manually fetching selected pasture's session data
                                getSelectedPastureSessionData({
                                  variables: {
                                    _pasture_ids: value,
                                    _start_date:
                                      Array.isArray(overallSummaryDateRange) &&
                                      overallSummaryDateRange[0]
                                        ? overallSummaryDateRange[0].startOf('date').toDate()
                                        : undefined,
                                    _end_date:
                                      Array.isArray(overallSummaryDateRange) &&
                                      overallSummaryDateRange[1]
                                        ? overallSummaryDateRange[1].endOf('date').toDate()
                                        : undefined,
                                  },
                                });
                              } else {
                                setSelectedPastureIds([]);
                                // resets session data when all pastures are de-selected
                                getSelectedPastureSessionData({
                                  variables: {
                                    _pasture_ids: [],
                                  },
                                });
                              }
                            }}
                            value={selectedPastureIds}
                          >
                            {pastures.map((pasture) => (
                              <Select.Option
                                key={pasture.id}
                                value={pasture.id}
                                data={pasture.name}
                              >
                                {pasture.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                        <div
                          className="selectBoxContainer analysisScreenDatePicker"
                          style={{ marginTop: 20 }}
                        >
                          <span className="selectBoxLabel" style={{ marginRight: 63 }}>
                            Date Range
                          </span>
                          <RangePicker
                            suffixIcon={!overallSummaryDateRange ? <CalendarOutlined /> : null}
                            value={overallSummaryDateRange}
                            format="MM/DD/YYYY"
                            style={{ width: 400 }}
                            separator={
                              <span style={{ color: '#bfbfbf', fontSize: 18, paddingRight: 20 }}>
                                ~
                              </span>
                            }
                            placeholder={['  Start Date', ' End Date']}
                            onCalendarChange={(momentValues, stringValues) => {
                              // storing dayjs instance of start date
                              const startDate = dayjs(stringValues[0], 'MM/DD/YYYY', true);
                              // storing dayjs instance of end date
                              const endDate = dayjs(stringValues[1], 'MM/DD/YYYY', true);
                              // converting selected date range into js date format
                              const dateRangeValues = {
                                startDate: startDate.isValid()
                                  ? startDate.startOf('date').toDate()
                                  : undefined,
                                endDate: endDate.isValid()
                                  ? endDate.endOf('date').toDate()
                                  : undefined,
                              };
                              // calculating overall summary table data only when both dates are selected
                              if (startDate.isValid() && endDate.isValid()) {
                                setOverallSummaryDateRange(momentValues);
                              } else {
                                setOverallSummaryDateRange(undefined);
                              }

                              // manually fetching selected pasture's session data
                              getSelectedPastureSessionData({
                                variables: {
                                  _pasture_ids: selectedPastureIds,
                                  _start_date: dateRangeValues.startDate,
                                  _end_date: dateRangeValues.endDate,
                                },
                              });
                            }}
                          />
                        </div>
                        {selectedPastureIds.length === 0 ? (
                          <div style={{ marginTop: 30 }}>
                            <InfoText>Select pasture(s) to view detection analysis result</InfoText>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </Panel>
              </Collapse>
            ) : (
              <div
                style={{
                  display: 'flex',
                  marginTop: 30,
                  justifyContent: 'center',
                  alignSelf: 'center',
                }}
              >
                <WarningText>
                  <>
                    Please add pasture to this Producer in <b>Pastures</b> tab of <b>Producers</b>{' '}
                    section
                  </>
                </WarningText>
              </div>
            )}
          </div>
        ) : null}
      </>

      {/* no image data warning logic */}
      {selectedPastureIds.length > 0 && showNoSessionWarning ? (
        <div style={{ marginTop: 15, display: 'flex' }}>
          <WarningText>
            No farm call and Image data present for analysis for current selection
          </WarningText>
        </div>
      ) : null}

      {/* no image data warning logic */}
      {selectedPastureIds.length > 0 && showNoImageWarning && !showNoSessionWarning ? (
        <div style={{ marginTop: 15, display: 'flex' }}>
          <WarningText>No image data present for analysis for current selection.</WarningText>
        </div>
      ) : null}

      {/* ---------------------rendering all tables and graph logic starts ------------------- */}
      {!showNoImageWarning && !showNoSessionWarning && selectedPastureIds.length > 0 ? (
        <div style={{ marginTop: 20 }}>
          {/* ------------ farm call summary with  pasture table containing sessions of individual pasture and line chart --------- */}
          <SectionWiseCard sectionHeaderElement="SUMMARY">
            <>
              <Space align="center" style={{ paddingLeft: 20, paddingTop: 20 }}>
                <div style={{ marginRight: 10, fontSize: 18 }}>
                  Hide all <b>excluded</b> rows from table
                </div>
                <Switch
                  checked={hideExcludedSessionTableData}
                  onChange={(checked) => {
                    setHideExcludedSessionTableData(checked);
                  }}
                />
              </Space>
              <SummaryMessageComponent />

              {allSessionsDataOfPasture.map((ele) => (
                <div className="sectionHeading" key={ele.id}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontFamily: 'HelveticaBold',
                      fontSize: 18,
                      marginLeft: 20,
                      marginTop: 15,
                      color: colors.greenColor,
                    }}
                  >
                    {ele.name}
                  </p>
                  <NewSessionWiseSummaryTable
                    setAllSessionsDataOfPasture={setAllSessionsDataOfPasture}
                    sessionWiseSummaryTableData={
                      hideExcludedSessionTableData
                        ? ele.sessions.filter((item) => item.rowAction !== 'exclude')
                        : ele.sessions
                    }
                    dataLoading={selectedPastureSessionLoading}
                    dataError={selectedPastureSessionError}
                    hideExcludedSessionData={hideExcludedSessionTableData}
                    control={control}
                  />
                </div>
              ))}

              {renderAvgFlyCountLegend()}
              <PastureSessionFlyCountChart
                sessionLineChartData={structuredSessionChartData}
                selectedPastureDetails={selectedPastureDetails}
                responsiveWidth="100%"
              />
            </>
          </SectionWiseCard>

          {/* --------------- Session wise fly count category distribution section starts ----------- */}

          {sessionWiseFlyCountCategoryDataForAllPasture.map((pasture) => (
            <SectionWiseCard
              sectionHeaderElement={`${pasture.name} FLY COUNT CATEGORY DISTRIBUTION`}
              customCardStyles={{ marginTop: 30, marginBottom: 20 }}
              key={pasture.id}
            >
              <>
                {Array.isArray(pasture.sessions) && pasture.sessions.length > 0 ? (
                  <div key={pasture.id}>
                    <SessionFlyCountDistributionSummaryTable tableData={pasture.sessions} />

                    {/* render radio button for chartType */}
                    {renderChipSelectionForChartType(
                      sessionFlyCountDistributionChartType,
                      setSessionFlyCountDistributionChartType,
                    )}

                    <SessionFlyCountDistributionChart
                      barData={pasture.sessions}
                      responsiveWidth="100%"
                      chartType={sessionFlyCountDistributionChartType}
                    />
                  </div>
                ) : null}
              </>
            </SectionWiseCard>
          ))}

          {/* render Export PDF and  Export CSV button */}
          <div
            style={{
              position: 'fixed',
              left: '50%',
              marginLeft: -120,
              zIndex: 999,
              bottom: 55,
              display: 'flex',
            }}
            className="producerBtnStyleContainer"
          >
            {showImgUnderAnalysisAlert ? (
              <Popconfirm
                title={
                  <p style={{ marginBottom: 0, width: 300 }}>
                    For your selected filters, some images are still processing. It’s recommended to
                    generate report after all images are processed. <br />
                    Are you sure to continue?
                  </p>
                }
                onConfirm={() => {
                  setShowAnalysisReport(true);
                }}
                icon={<WarningFilled style={{ color: colors.appRedColor }} />}
                cancelText="No"
                okText="Yes"
              >
                <Button>Generate Report</Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={() => {
                  setShowAnalysisReport(true);
                }}
              >
                Generate Report
              </Button>
            )}
            <Button
              style={{ marginLeft: 20, paddingTop: 3 }}
              onClick={() => {
                handleCsvConversion();
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewAnalysisScreen;
