/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { CSSProperties } from 'react';
import { Row, Col } from 'antd';
import styles from './FormItem.module.scss';

// type definition for FormItemComponent props
type FormItemComponentProps = {
  // indicates the label of field
  label?: string | JSX.Element;
  // indicates whether field is required
  isFieldRequired?: boolean;
  // error message to displayed if any
  errorText?: string;
  // indicates children component to be passed
  children: JSX.Element;
  // This is the span which is passed in the <Col> for label
  labelColSpan?: number;
  // This is the span which is passed in the <Col> for input
  inputColSpan?: number;
  // This is the help text which describes the input field
  helpText?: string;
  // this is the style which we pass from parent component
  customStyle?: CSSProperties;
  /* custom style for error text if necessary */
  errorTextStyle?: CSSProperties;
};

const FormItemComponent: React.FC<FormItemComponentProps> = (props) => {
  const {
    label = undefined,
    isFieldRequired = false,
    errorText = '',
    children,
    labelColSpan = 3,
    inputColSpan = 21,
    helpText = '',
    customStyle = {},
    errorTextStyle = {},
  } = props;
  return (
    <>
      <Row className={styles.inputContainer} style={{ paddingTop: 25, ...customStyle }}>
        {label ? (
          <Col
            span={labelColSpan}
            className={`${styles.labelName} ${isFieldRequired ? 'requiredField' : ''}`}
          >
            <label className="form-label">{label}</label>
          </Col>
        ) : null}
        <Col span={inputColSpan} style={label ? { paddingLeft: 30 } : undefined}>
          {children}
          {helpText ? <div className={styles.helpText}>{helpText}</div> : null}
        </Col>
      </Row>
      {errorText ? (
        <Row>
          <Col
            span={inputColSpan}
            offset={labelColSpan}
            className={styles.errorText}
            style={errorTextStyle}
          >
            {errorText}
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default FormItemComponent;
