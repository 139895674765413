import React from 'react';
import AppRoutes from './AppRoutes';
import './App.scss';
import LoadingScreen from './screens/LoadingScreen';
import LoginScreen from './screens/LoginScreen';
import useAuth from './utils/auth/useAuth';
import 'react-image-lightbox/style.css';

/* React functional component */
const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();

  /* Condition for display loader while checking for user is logged in or not */
  if (isLoading) return <LoadingScreen />;

  /* When user is logged in and loader is false */
  if (isAuthenticated) {
    return <AppRoutes />;
  }

  /* When user is not logged in */
  return <LoginScreen />;
};

export default App;
