import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import {
  GetUserWiseUsageStatisticsQuery,
  GetUserWiseUsageStatisticsQueryVariables,
} from '../graphql/graphql-types';
import { UserUsageStatistic } from '../utils/types';

const userWiseUsageQuery = loader('../graphql/queries/userWiseUsageStatisticsQuery.graphql');

// This is the Main functional component
const UsageStatisticsScreen: React.FC = () => {
  // obtains the data of user wise usage
  const { data, loading, error } = useQuery<
    GetUserWiseUsageStatisticsQuery,
    GetUserWiseUsageStatisticsQueryVariables
  >(userWiseUsageQuery);

  // stores the related data
  const usageData = data?.user;

  return (
    <div style={{ padding: 20 }}>
      {error ? <div className="errorMessage">{error.message}</div> : null}
      {usageData && Array.isArray(usageData) && usageData.length > 0 ? (
        <Table
          dataSource={usageData}
          bordered
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            position: ['bottomRight'],
            size: 'default',
          }}
          style={{ padding: '20px 0px' }}
          size="small"
          loading={loading}
          rowKey="id"
        >
          <Table.Column<UserUsageStatistic> key="id" title="ID" align="center" dataIndex="id" />
          <Table.Column<UserUsageStatistic>
            key="producersAdded"
            title="Producers added"
            align="center"
            dataIndex="producersAdded"
            render={(text, record): string | number =>
              record.farm_created_by_aggregate.aggregate &&
              record.farm_created_by_aggregate.aggregate &&
              record.farm_created_by_aggregate.aggregate.count
                ? record.farm_created_by_aggregate.aggregate.count
                : '-'
            }
          />
          <Table.Column<UserUsageStatistic>
            key="pasturesAdded"
            title="Pastures added"
            align="center"
            dataIndex="pasturesAdded"
            render={(text, record): string | number =>
              record.pasture_created_by_aggregate.aggregate &&
              record.pasture_created_by_aggregate.aggregate &&
              record.pasture_created_by_aggregate.aggregate.count
                ? record.pasture_created_by_aggregate.aggregate.count
                : '-'
            }
          />
          <Table.Column<UserUsageStatistic>
            key="totalImages"
            title="Total images"
            align="center"
            dataIndex="totalImages"
            render={(text, record): string | number =>
              record.image_created_by_aggregate &&
              record.image_created_by_aggregate.aggregate &&
              record.image_created_by_aggregate.aggregate.count
                ? record.image_created_by_aggregate.aggregate.count
                : '-'
            }
          />
          <Table.Column<UserUsageStatistic>
            key="rejectedImages"
            title="Rejected images"
            align="center"
            dataIndex="rejectedImages"
            render={(text, record): string | number =>
              record.rejected_images &&
              record.rejected_images.aggregate &&
              record.rejected_images.aggregate.count
                ? record.rejected_images.aggregate.count
                : '-'
            }
          />
          <Table.Column<UserUsageStatistic>
            key="percentRejectedImages"
            title="% Rejected images"
            align="center"
            dataIndex="percentRejectedImages"
            render={(text, record): string => {
              // stores the value of rejected image count
              const rejectedImagesCount =
                record.rejected_images &&
                record.rejected_images.aggregate &&
                record.rejected_images.aggregate.count
                  ? record.rejected_images.aggregate.count
                  : 0;
              // stores the value of total images count
              const totalImagesCount =
                record.image_created_by_aggregate &&
                record.image_created_by_aggregate.aggregate &&
                record.image_created_by_aggregate.aggregate.count
                  ? record.image_created_by_aggregate.aggregate.count
                  : 0;
              // stores the value of percent of rejected images
              let percentRejectedImages = '-';
              if (totalImagesCount !== 0) {
                percentRejectedImages = ((rejectedImagesCount / totalImagesCount) * 100).toFixed(0);
              }
              return percentRejectedImages;
            }}
          />
        </Table>
      ) : null}
    </div>
  );
};
export default UsageStatisticsScreen;
