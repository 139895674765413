import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useQuery, useLazyQuery } from '@apollo/client';
import axios from 'axios';
import mergeImages from 'merge-images';
import { loader } from 'graphql.macro';
import { Modal, Spin } from 'antd';
import {
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  ViewPredictionImagesForSessionQuery,
  ViewPredictionImagesForSessionQueryVariables,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import { ReactComponent as AlertBlue } from '../assets/icons/Alert Blue.svg';
import colors from '../utils/variables.module.scss';
import styles from './ImageCardModalAndLightbox.module.scss';
import InfoText from './InfoText';

/* type for props of image LightBoxComponent */
type ImageCardModalAndLightboxPropType = {
  /* prop used to set close the visibility of image card modal  */
  setImageModalVisibility: React.Dispatch<React.SetStateAction<string | null>>;
  // prop use to store session id whose view image button is clicked
  sessionId: string;
};

// type for merged images Array state
type MergedImagesArrayType = Array<{
  // type for url which store merged url of the image
  url: string;
  // type of const which store category of the image
  category: string;
  // type of const which store avgFlyCount of the image
  avgFlyCount: number;
}>;

// type for thumbnail images Array state
type ThumbnailImagesArrayType = Array<{
  // type for url which store thumbnail url of the image
  thumbnailUrl: string;
  // type for of const which store category of the image
  category: string;
  // type for of const which store avgFlyCount of the image
  avgFlyCount: number;
}>;

// loading getFilesUrlQuery
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
// loading viewPredictionImagesForSession query
const viewPredictionImagesForSession = loader(
  '../graphql/queries/viewPredictionImagesForSessionQuery.graphql',
);

/* React functional component */
const ImageCardModalAndLightbox: React.FC<ImageCardModalAndLightboxPropType> = ({
  setImageModalVisibility,
  sessionId,
}) => {
  // // state used to store array of merged image url, category, average fly count object for every image which is use to show data and image in image lightbox
  // const [mergedImagesArray, setMergedImagesArray] = useState<MergedImagesArrayType>([]);

  // /* state used to store the index of mergedImagesArray to manage pre, next while displaying image */
  // const [photoIndex, setPhotoIndex] = useState<number>(0);

  // // state use to set image lightbox visibility this will be true when user click on particular thumbnail image
  // const [isImgLightboxVisible, setIsImgLightboxVisible] = useState<boolean>(false);

  // // state use to set in loading true until we process merge image url so that we can show loading in caption of image lightbox
  // const [imgProcessing, setImgProcessing] = useState<boolean>(false);

  // // as mergeImages library didn't work with dynamic image url we are locally storing image and using locally store url which then pass to mergeImages.
  // const downloadImage = async (imagesSrc: string) => {
  //   // eslint-disable-next-line no-await-in-loop
  //   const image = await axios.get(imagesSrc, { responseType: 'blob' });
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //   const imageBlob = new Blob([image.data]);
  //   const imageURL = URL.createObjectURL(imageBlob);
  //   return imageURL;
  // };

  // get files url lazy query
  const [
    getFilesUrlFunc,
    { error: getFilesUrlError, loading: getFilesUrlLoading, data: getFilesUrlData },
  ] = useLazyQuery<GetFilesUrlQuery, GetFilesUrlQueryVariables>(getFilesUrlQuery);

  // call to viewPredictionImagesForSessionQuery
  const { loading, error, data: queryData } = useQuery<
    ViewPredictionImagesForSessionQuery,
    ViewPredictionImagesForSessionQueryVariables
  >(viewPredictionImagesForSession, {
    variables: {
      _session_id: sessionId,
    },
    // onComplete to call getFiles url query so that will get url of all images form their s3Keys
    onCompleted: (res) => {
      // setImgProcessing(true);

      /* Variable to store images data which can be used further for display */
      const imagesToDisplayData: ViewPredictionImagesForSessionQuery['view_calculations_for_display_images'] = [];

      if (res && Array.isArray(res.view_calculations_for_display_images)) {
        res.view_calculations_for_display_images.forEach((item) => {
          /* Variable to store category image is already present or not */
          const isCategoryImagePresent = imagesToDisplayData.find(
            (val) => val.image_display?.category === item.image_display?.category,
          );

          if (!isCategoryImagePresent) {
            imagesToDisplayData.push(item);
          }
        });
      }

      // const only to  store required image data which we are going to display in lightbox
      const imageUrlsData =
        imagesToDisplayData.length > 0
          ? imagesToDisplayData.map((ele) => ({
              originalImgS3Key:
                ele.image_display && ele.image_display.image_s3_key
                  ? ele.image_display.image_s3_key
                  : '',
              overlayImgS3Key:
                ele.image_display && ele.image_display.prediction_overlay_image_s3_key
                  ? ele.image_display.prediction_overlay_image_s3_key
                  : '',
            }))
          : undefined;

      if (imageUrlsData) {
        // const to store only s3Keys array so that we can pass string array to getFilesUrlQuery variable
        const allImagesKeys = imageUrlsData.map(({ originalImgS3Key, overlayImgS3Key }) => ({
          originalImgS3Key,
          overlayImgS3Key,
          thumbnailS3Key: `thumbnails/${originalImgS3Key}`,
        }));

        // call to lazy query function
        getFilesUrlFunc({
          variables: {
            keys: allImagesKeys
              .map((ele) => Object.values(ele).filter((item) => item !== ''))
              .flat(1),
          },
        });
      }
    },
  });

  // const only to  store required image data which we are going to display in lightbox and img card
  const imageRawData =
    queryData &&
    Array.isArray(queryData.view_calculations_for_display_images) &&
    queryData.view_calculations_for_display_images.length > 0
      ? queryData.view_calculations_for_display_images.map((ele) => ({
          originalImgS3Key:
            ele.image_display && ele.image_display.image_s3_key
              ? ele.image_display.image_s3_key
              : '',
          overlayImgS3Key:
            ele.image_display && ele.image_display.prediction_overlay_image_s3_key
              ? ele.image_display.prediction_overlay_image_s3_key
              : '',
          category: ele.image_display ? (ele.image_display.category as string) : '',
          predictionAvgFlyCount: ele.predicted_fly_count_avg as number,
        }))
      : undefined;

  // storing getFilesUrl from getFilesUrlQuery
  const getFilesUrl = getFilesUrlData && getFilesUrlData.getFilesUrl;

  // temporary array to store basic data fpf thumbnail img
  const thumbnailImagesArray: ThumbnailImagesArrayType = [];

  // logic to get basic data which will be displayed in thumbnail img card
  if (imageRawData && getFilesUrl) {
    imageRawData.forEach((ele) => {
      // const to store thumbnail of the original url
      const thumbnailImgUrl = getFilesUrl
        ? getFilesUrl.find((item) => item?.key === `thumbnails/${ele.originalImgS3Key}`)?.url
        : undefined;

      // const to store original img url
      const originalImgUrl = getFilesUrl
        ? getFilesUrl.find((item) => item?.key === ele.originalImgS3Key)?.url
        : undefined;

      if (thumbnailImgUrl && originalImgUrl) {
        thumbnailImagesArray.push({
          thumbnailUrl: thumbnailImgUrl,
          category: ele.category,
          avgFlyCount: ele.predictionAvgFlyCount,
        });
      }
    });
  }

  // logic to get combined original and overlay image url from  original and overlay img individual url.
  //  convert individual img url into local urls => pass them to mergeImages library to get merged url
  // logic to show error
  if (error || getFilesUrlError) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setImageModalVisibility(null);
    return (
      <div
        className={styles.alignContentAndItemsToCenter}
        style={{ fontSize: 16, color: colors.appRedColor }}
      >
        {error?.message || getFilesUrlError?.message}
      </div>
    );
  }

  return (
    <>
      <Modal
        width={450}
        centered
        style={{ padding: 20 }}
        visible
        footer={null}
        closable
        onCancel={() => {
          setImageModalVisibility(null);
        }}
        maskClosable={false}
      >
        {loading || getFilesUrlLoading ? (
          <div
            className={styles.alignContentAndItemsToCenter}
            style={{ height: 540, flexDirection: 'column', fontSize: 16 }}
          >
            <Spin size="large" />
            Loading images
          </div>
        ) : (
          <div
            style={{
              height:
                queryData && queryData.view_calculations_for_display_images.length === 0
                  ? 540
                  : 640,
            }}
          >
            {queryData && queryData.view_calculations_for_display_images.length === 0 ? (
              <div
                className={styles.alignContentAndItemsToCenter}
                style={{
                  flexDirection: 'column',
                  height: 540,
                }}
              >
                <AlertBlue width={100} height={100} />
                <div style={{ fontSize: 16, textAlign: 'center', padding: '10px 15px' }}>
                  No images have been filtered for display for this farm call
                </div>
              </div>
            ) : (
              <>
                <h2 className={styles.imageHeading}>Images</h2>
                <div style={{ margin: '10px 15px' }}>
                  <InfoText>
                    <div>
                      Images that have been processed and uploaded to web are considered for
                      displaying here.
                    </div>
                  </InfoText>
                </div>
                <div
                  className={styles.alignContentAndItemsToCenter}
                  style={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                  }}
                >
                  {thumbnailImagesArray.map((ele, index) => {
                    // variable to store  background color based on category
                    let imageDivBgColor;
                    if (ele.category === 'critical') {
                      imageDivBgColor = colors.criticalColor;
                    } else if (ele.category === 'poor') {
                      imageDivBgColor = colors.poorColor;
                    } else if (ele.category === 'good') {
                      imageDivBgColor = colors.goodColor;
                    } else {
                      imageDivBgColor = colors.excellentColor;
                    }

                    return (
                      <div
                        style={{ margin: 10, width: '40%' }}
                        // onClick={() => {
                        //   getMergeImagesUrl(index).catch((err) => logger(err));
                        // }}
                        key={ele.thumbnailUrl}
                      >
                        <div className={styles.image}>
                          <img className={styles.image} alt="img" src={ele.thumbnailUrl} />
                        </div>
                        <div
                          style={{
                            backgroundColor: imageDivBgColor,
                            textAlign: 'center',
                            padding: 5,
                          }}
                          className={styles.imageDescription}
                        >
                          <p className={styles.imageDescHeading}>{ele.category}</p>
                          <p
                            style={{
                              marginBottom: -3,
                              marginTop: -6,
                              color: colors.whiteColor,
                              fontSize: 14,
                            }}
                          >
                            Average Fly Count
                          </p>
                          <p className={styles.imageDescHeading}>{ele.avgFlyCount.toFixed(0)}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </Modal>

      {/* {isImgLightboxVisible ? (
        <Lightbox
          mainSrc={mergedImagesArray[photoIndex] ? mergedImagesArray[photoIndex].url : ''}
          nextSrc={
            mergedImagesArray[(photoIndex + 1) % mergedImagesArray.length]
              ? mergedImagesArray[(photoIndex + 1) % mergedImagesArray.length].url
              : ''
          }
          prevSrc={
            mergedImagesArray[
              (photoIndex + mergedImagesArray.length - 1) % mergedImagesArray.length
            ]
              ? mergedImagesArray[
                  (photoIndex + mergedImagesArray.length - 1) % mergedImagesArray.length
                ].url
              : ''
          }
          onCloseRequest={() => {
            setIsImgLightboxVisible(false);
            mergedImagesArray.forEach((ele) => {
              URL.revokeObjectURL(ele.url);
            });
          }}
          onMovePrevRequest={() => {
            if (mergedImagesArray.length > 1) {
              setPhotoIndex((photoIndex + mergedImagesArray.length - 1) % mergedImagesArray.length);
            }
          }}
          onMoveNextRequest={() => {
            if (mergedImagesArray.length > 1) {
              setPhotoIndex((photoIndex + 1) % mergedImagesArray.length);
            }
          }}
          imageCaption={
            <div
              style={{
                fontSize: 18,
                color: 'white',
              }}
            >
              {imgProcessing ? (
                <p>
                  We are working in the background to display the prediction images. Please wait.
                </p>
              ) : (
                <div style={{ textTransform: 'capitalize' }}>
                  <b>Category: </b> {mergedImagesArray[photoIndex].category}
                  <br />
                  <b>Prediction Average Fly Count: </b>
                  {mergedImagesArray[photoIndex].avgFlyCount.toFixed(0)}
                </div>
              )}
            </div>
          }
          clickOutsideToClose={false}
        />
      ) : null} */}
    </>
  );
};

export default ImageCardModalAndLightbox;
