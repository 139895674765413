import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Button, Divider } from 'antd';

// This Props are coming from parent component
type HeadingProps = {
  // This is the name of the heading which we want in the above of any page or component
  headingName: string | JSX.Element;
  // whether to show a horizontal line below title
  renderHrLine?: boolean;
  // setting heading color
  headingColor?: string;
  // This is for showing close icon
  showCrossIcon?: boolean;
  // This props is used for click of close icon
  handleCrossClick?: () => void;
  // whether to show/hide options button
  showOptions?: boolean;
  // when showOptions is true, this method handle its onClick event
  onOptionsClick?: () => void;
};

// This is the main react component
const Heading: React.FC<HeadingProps> = ({
  headingName,
  renderHrLine = true,
  headingColor = '#000',
  showCrossIcon = false,
  handleCrossClick = () => {},
  showOptions = false,
  onOptionsClick = () => {},
}) => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ fontSize: 22, color: headingColor }}>{headingName}</div>
      <div>
        {showCrossIcon ? <CloseOutlined onClick={handleCrossClick} /> : null}
        {showOptions ? (
          <Button
            type="link"
            style={{ background: 'none', color: '#1890ff', border: 'none' }}
            onClick={onOptionsClick}
          >
            <FontAwesomeIcon icon={faCog} style={{ color: '#1890ff' }} />
            <span style={{ paddingLeft: 6 }}>Options</span>
          </Button>
        ) : null}
      </div>
    </div>
    {renderHrLine ? (
      <Divider
        style={{ marginTop: 0, backgroundColor: 'grey', marginBottom: 12, borderWidth: 1 }}
      />
    ) : null}
  </div>
);

export default Heading;
