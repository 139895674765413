import { createContext } from 'react';
import { AuthUserDataType, AuthUserRole } from '../pingfederate/pingfederate';

export type AuthContextType = {
  // Is the user logged in?
  isAuthenticated: boolean;
  // Loading state of auth context
  isLoading: boolean;
  // Function to get the active access token
  getAccessToken: () => Promise<string>;
  // Start's the SSO login process
  login: () => Promise<void>;
  // Auth/SSO logout
  logout: () => Promise<void>;
  // SSO User
  user: AuthUserDataType;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  getAccessToken: stub,
  login: stub,
  logout: stub,
  user: {
    ag2ag: '',
    email: '',
    firstName: '',
    lastName: '',
    role: AuthUserRole.user,
  },
});

export default AuthContext;
