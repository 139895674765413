import React from 'react';
import styles from './FarmDetails.module.scss';
import { addressFormat } from '../utils/helpers';
import { FarmData } from '../utils/types';

// type definition for props
type FarmDetailComponentProps = {
  // this is the data of farm which is  to be shown
  farmDataToShow: FarmData;
};

// This is the main functional component
const FarmDetailComponent: React.FC<FarmDetailComponentProps> = (props) => {
  const { farmDataToShow } = props;
  return (
    <div style={{ padding: 25 }}>
      {farmDataToShow.name ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Producer Name:</span>
          <p className={styles.value}>{farmDataToShow.name}</p>
        </div>
      ) : null}
      {farmDataToShow.manager_name ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Name:</span>
          <p className={styles.value}>{farmDataToShow.manager_name}</p>
        </div>
      ) : null}
      {farmDataToShow.manager_email ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Email:</span>
          <p className={styles.value}>{farmDataToShow.manager_email}</p>
        </div>
      ) : null}
      {farmDataToShow.manager_phone ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Phone:</span>
          <p className={styles.value}>{farmDataToShow.manager_phone}</p>
        </div>
      ) : null}
      {farmDataToShow.address_line_1 ||
      farmDataToShow.address_line_2 ||
      farmDataToShow.city ||
      farmDataToShow.state ||
      farmDataToShow.zipcode ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Address:</span>
          <p className={styles.value}>
            {addressFormat({
              addressLine1: farmDataToShow.address_line_1 ? farmDataToShow.address_line_1 : null,
              addressLine2: farmDataToShow.address_line_2 ? farmDataToShow.address_line_2 : null,
              city: farmDataToShow.city ? farmDataToShow.city : null,
              state: farmDataToShow.state ? farmDataToShow.state : null,
              zip: farmDataToShow.zipcode,
            })}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default FarmDetailComponent;
