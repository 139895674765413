import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { Button, Table, Popover } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import InfoText from '../components/InfoText';
import {
  GetPastureSessionsQuery,
  GetPastureSessionsQueryVariables,
} from '../graphql/graphql-types';
import Heading from '../components/Heading';
import { PastureType, SessionSummaryTable } from '../utils/types';
import SessionCommentsFormModal from '../forms/SessionCommentsFormModal';
import { ReactComponent as ProducerCircle } from '../assets/icons/Producer_Circle_Blue.svg';
import { ReactComponent as InfoCircle } from '../assets/icons/Information_Circle.svg';
import { ReactComponent as AlertBlue } from '../assets/icons/Alert Blue.svg';
import colors from '../utils/variables.module.scss';
import ImageCardModalAndLightBox from '../components/ImageCardModalAndLightbox';
import { ReactComponent as CommentIcon } from '../assets/icons/Comment_Bubble.svg';

const getPastureSessionsQuery = loader('../graphql/queries/getPastureSessionsQuery.graphql');

// This is the type of variable which is coming from parent component
type PastureImagesSummaryProps = {
  // This is the detail of the pasture
  pastureDetail: PastureType;
};

// This is the main functional component
const PastureImagesSummary: React.FC<PastureImagesSummaryProps> = ({ pastureDetail }) => {
  // state use to set session id of rows to know whose view image button is clicked and which is also used to show thumbnail image modal and lightbox component
  const [viewImagesForSessionId, setViewImagesForSessionId] = useState<string | null>(null);

  // state use to set the session comment modal visibility
  const [isSessionCommentsModalVisible, setIsSessionCommentsModalVisible] = useState<boolean>(
    false,
  );

  // state use to set the session object to get basic session details whose comment button is clicked
  const [
    selectedSessionForComment,
    setSelectedSessionForComment,
  ] = useState<SessionSummaryTable | null>(null);

  // fetching pasture wise session data
  const { loading, error, data: allPastureSessions } = useQuery<
    GetPastureSessionsQuery,
    GetPastureSessionsQueryVariables
  >(getPastureSessionsQuery, {
    variables: {
      _pasture_id: pastureDetail.id,
    },
    fetchPolicy: 'network-only',
  });

  // storing all sessions data for selected pasture
  const sessionsData = allPastureSessions?.session;

  // stores fetched cow count for selected pasture
  const pastureCowCount = allPastureSessions?.pasture_by_pk?.cows_count as number | undefined;

  return (
    <div style={{ padding: 30 }}>
      <Heading headingName="Farm Call Summary" />
      <div
        style={{
          padding: '10px 10px 6px 15px',
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: colors.blueColor,
          fontFamily: 'HelveticaBold',
          color: colors.whiteColor,
          borderRadius: 5,
          width: 350,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
            <ProducerCircle width={40} height={40} />
            <span style={{ fontSize: 18, marginTop: 2, marginLeft: 10 }}>Head Count</span>
          </div>
          <div style={{ fontSize: 18, fontWeight: 'bold', marginRight: 30 }}>
            {pastureCowCount || '-'}
          </div>
        </div>
      </div>
      {error ? <div className="errorMessage">{error.message}</div> : null}

      {!loading && (!Array.isArray(sessionsData) || sessionsData.length === 0) ? (
        <InfoText>No farm call is present for this pasture</InfoText>
      ) : (
        <Table
          dataSource={sessionsData}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            position: ['bottomRight'],
            size: 'default',
          }}
          style={{ padding: '10px 0' }}
          rowKey="id"
          loading={loading}
          size="middle"
          className="producerTable"
          rowClassName="pastureSessionSummaryRow"
        >
          <Table.Column<SessionSummaryTable>
            title={<b>Farm Call Name</b>}
            dataIndex="pasture_session_name"
            render={(text, { pasture_session_name }) =>
              !pasture_session_name ? '-' : pasture_session_name
            }
          />
          <Table.Column<SessionSummaryTable>
            title={<b>Farm Call Start Date</b>}
            dataIndex="start_date"
            render={(text, { start_date }) => dayjs(start_date).format('MMM DD, YYYY')}
          />
          <Table.Column<SessionSummaryTable>
            title={<b>Farm Call End Date</b>}
            dataIndex="end_date"
            render={(text, { end_date }) => dayjs(end_date).format('MMM DD, YYYY')}
          />
          <Table.Column<SessionSummaryTable>
            title={<b>Images Taken</b>}
            align="center"
            dataIndex={['view_images_aggregate', 'aggregate', 'count']}
            render={(text: number) => text || '-'}
          />
          <Table.Column<SessionSummaryTable>
            title={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <b>Rejected Images</b>
                <Popover
                  content={
                    <div
                      style={{ display: 'flex', alignItems: 'center', width: 350, flex: 'wrap' }}
                    >
                      <AlertBlue style={{ width: 30, height: 30, marginRight: 10 }} />
                      <p style={{ fontSize: 14, marginBottom: 0, color: colors.blueColor }}>
                        Images are commonly rejected due to lighting or shading, multiple animals in
                        photo, animal position, distance from cattle or blurry images.
                      </p>
                    </div>
                  }
                  title={false}
                >
                  <InfoCircle style={{ marginLeft: 10, width: 18, height: 18 }} />
                </Popover>
              </div>
            }
            align="center"
            dataIndex={['session_rejected_images', 'aggregate', 'count']}
            render={(text: number) => text || '-'}
          />
          <Table.Column<SessionSummaryTable>
            key="option"
            title={<div style={{ marginLeft: 150 }}>Actions</div>}
            dataIndex="option"
            align="center"
            render={(text, record) => (
              <div
                className="producerBtnStyleContainer"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  icon={<EyeOutlined />}
                  onClick={(): void => {
                    setViewImagesForSessionId(record.id);
                  }}
                >
                  View Images
                </Button>
                <Button
                  icon={<CommentIcon style={{ marginRight: 5, width: 20, height: 20 }} />}
                  style={{ marginRight: 10, marginLeft: 10 }}
                  onClick={() => {
                    setIsSessionCommentsModalVisible(true);
                    setSelectedSessionForComment(record);
                  }}
                >
                  Comments
                </Button>
              </div>
            )}
          />
        </Table>
      )}
      {isSessionCommentsModalVisible && selectedSessionForComment ? (
        <SessionCommentsFormModal
          modalVisibility={isSessionCommentsModalVisible}
          setModalVisibility={setIsSessionCommentsModalVisible}
          calledFrom="pastureScreen"
          sessionId={selectedSessionForComment.id}
          sessionDetails={{
            pasture_session_name: selectedSessionForComment.pasture_session_name,
            start_date: selectedSessionForComment.start_date,
            comment: selectedSessionForComment.comment,
            pastureName: pastureDetail.name,
            pastureId: pastureDetail.id,
          }}
          infoMessage="Please use mobile app for updating comments"
        />
      ) : null}
      {viewImagesForSessionId ? (
        <ImageCardModalAndLightBox
          sessionId={viewImagesForSessionId}
          setImageModalVisibility={setViewImagesForSessionId}
        />
      ) : null}
    </div>
  );
};

export default PastureImagesSummary;
