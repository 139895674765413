import React from 'react';
import colors from '../utils/variables.module.scss';

// prop type for section wise card component
type SectionWiseCardPropType = {
  // this prop is used to pass string or jsx element this header bascially has appRed Background
  sectionHeaderElement?: string | JSX.Element;
  // this prop is use to pass custom style to card
  customCardStyles?: React.CSSProperties;
  // this prop is use to pass custom style to section header
  customSectionHeaderStyles?: React.CSSProperties;
  // this prop render jsx children
  children: JSX.Element;
};

// react functional component to render section card
const SectionWiseCard: React.FC<SectionWiseCardPropType> = ({
  sectionHeaderElement = undefined,
  children,
  customCardStyles = {},
  customSectionHeaderStyles = {},
}) => (
  <div
    style={{
      boxShadow: `0px 1px 3px ${colors.greyColor}`,
      paddingBottom: 20,
      ...customCardStyles,
    }}
  >
    {sectionHeaderElement ? (
      <div
        style={{
          backgroundColor: colors.greenColor,
          padding: '8px 20px 2px 20px',
          color: colors.whiteColor,
          fontFamily: 'HelveticaBold',
          fontSize: 20,
          textTransform: 'uppercase',
          ...customSectionHeaderStyles,
        }}
      >
        {sectionHeaderElement}
      </div>
    ) : null}
    {children}
  </div>
);

export default SectionWiseCard;
