import React from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Select, Spin, Row, Col } from 'antd';
import {
  AllFarmsQuery,
  AllFarmsQueryVariables,
  PasturesOfFarmQuery,
  PasturesOfFarmQueryVariables,
} from '../graphql/graphql-types';
import WarningText from '../components/WarningText';
import InfoText from '../components/InfoText';
import PastureImagesSummary from './PastureImagesSummary';
import { PastureType } from '../utils/types';

const allFarmsQuery = loader('../graphql/queries/allFarmsQuery.graphql');
const pasturesOfFarmQuery = loader('../graphql/queries/pasturesOfFarmQuery.graphql');

// This is the main functional component
const PasturesScreen: React.FC = () => {
  // This state stores the id of farm
  const [selectedFarmId, setSelectedFarmId] = React.useState<string | undefined>(undefined);

  // This state stores the detail of selected pastures
  const [selectedPasture, setSelectedPasture] = React.useState<PastureType | undefined>(undefined);

  // This is the data of the farm fetched from the graphql
  const { data, loading, error } = useQuery<AllFarmsQuery, AllFarmsQueryVariables>(allFarmsQuery);

  // farms store the value of all the farms coming from graphql
  const farms = data?.farm;

  /* fetching data of pasture from selected farm;
     if you want to execute a query in response to a different event then we use  useLazyQuery,
     so, Here we are using useLazyQuery for fetching the query after selection of farm */
  const [
    getPastureData,
    { data: pasturesData, loading: isLoadingPastureData, error: pastureDataQueryErrors },
  ] = useLazyQuery<PasturesOfFarmQuery, PasturesOfFarmQueryVariables>(pasturesOfFarmQuery);

  // This is the data of pasture
  const pastures = pasturesData?.pasture;

  // loading is a boolean value that indicates whether the request is in flight and show spinner
  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  // This error will display when error occur during fetching farm data
  if (error || pastureDataQueryErrors) {
    return (
      <div className="errorMessage">
        {pastureDataQueryErrors ? pastureDataQueryErrors.message : null}
        {error ? error.message : null}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 30, marginLeft: 20 }}>
      <div style={{ marginLeft: 35, marginBottom: 30 }}>
        <InfoText>
          Please use the Fly Monitor mobile app to add new pasture, update cow head count and farm
          call comments, or delete existing pasture or farm calls.
        </InfoText>
      </div>
      {Array.isArray(farms) && farms.length > 0 ? (
        <>
          <div style={{ marginLeft: 35 }}>
            {/* farm select box start */}
            <Row className="selectBoxContainer">
              <Col className="selectBoxLabel" style={{ textAlign: 'end' }}>
                Select a Producer :
              </Col>
              <Col>
                <Select
                  loading={loading}
                  className="selectBox"
                  showSearch
                  style={{ width: 400 }}
                  placeholder="Select a producer to view list of pastures"
                  optionFilterProp="data"
                  onChange={(value: string): void => {
                    if (value) {
                      setSelectedPasture(undefined);
                      setSelectedFarmId(value);
                      getPastureData({ variables: { _farm_id: value } });
                    }
                  }}
                  value={selectedFarmId}
                >
                  {farms.map((item) => (
                    <Select.Option key={item.id} value={item.id} data={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {/* farm select box end */}
            {selectedFarmId ? (
              <>
                {/* pasture select box, add pasture & delete pasture button start */}
                <Row style={{ marginTop: 20 }} className="selectBoxContainer">
                  <Col className="selectBoxLabel" style={{ textAlign: 'end' }}>
                    Select a Pasture :
                  </Col>
                  <Col>
                    <Select
                      className="selectBox"
                      loading={isLoadingPastureData}
                      showSearch
                      disabled={Array.isArray(pastures) && pastures.length === 0}
                      style={{ width: 400, marginLeft: 26 }}
                      placeholder="Select a pasture to view related image data"
                      optionFilterProp="data"
                      onChange={(value) => {
                        if (value && Array.isArray(pastures) && pastures.length > 0) {
                          const singlePasture = pastures.find((item) => item.id === value);
                          setSelectedPasture(singlePasture);
                        }
                      }}
                      value={!selectedPasture ? undefined : selectedPasture.id}
                    >
                      {pastures &&
                        pastures.map((item) => (
                          <Select.Option key={item.id} value={item.id} data={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                {/* pasture select box, add pasture & delete pasture button end */}
                {!isLoadingPastureData && (!Array.isArray(pastures) || pastures.length === 0) ? (
                  <Row style={{ marginTop: 20, marginLeft: 165 }}>
                    <InfoText>Please add pastures to this producer using the mobile app.</InfoText>
                  </Row>
                ) : null}
              </>
            ) : null}
          </div>
          {selectedPasture ? <PastureImagesSummary pastureDetail={selectedPasture} /> : null}
        </>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <WarningText>Please add a producer using the mobile app.</WarningText>
        </div>
      )}
    </div>
  );
};

export default PasturesScreen;
