import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import styles from './InfoText.module.scss';

// This is the type of props coming from parent component
type InfoTextProps = {
  // this is the children of this component
  children: JSX.Element | string | (string | JSX.Element)[];
};

// This is the main functional component
const InfoText: React.FC<InfoTextProps> = (props) => {
  // destructuring props
  const { children } = props;
  return (
    <div className={styles.infoText}>
      <FontAwesomeIcon icon={faInfo} className={styles.infoIcon} />
      {children}
    </div>
  );
};

export default InfoText;
