import React from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { SessionFlyCountDistributionSummary } from '../utils/types';

// component props
type SessionFlyCountDistributionSummaryProps = {
  // table datasource
  tableData: SessionFlyCountDistributionSummary[];
};

const SessionFlyCountDistributionSummaryTable = ({
  tableData,
}: SessionFlyCountDistributionSummaryProps): JSX.Element => (
  <div className="analysisTable">
    <Table<SessionFlyCountDistributionSummary>
      pagination={false}
      dataSource={tableData}
      rowKey={(record) => record.id}
      size="small"
      style={{ padding: '20px 20px 20px 20px' }}
    >
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Farm Call Start Date</span>}
        dataIndex="startDate"
        render={(text, { startDate }) => dayjs(startDate).format('MM/DD/YYYY')}
      />
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Accepted Images</span>}
        align="center"
        dataIndex="totalAcceptedImgCount"
        render={(text, { totalAcceptedImgCount }) =>
          !totalAcceptedImgCount ? '-' : totalAcceptedImgCount
        }
      />
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Excellent %</span>}
        align="center"
        dataIndex="excellent"
        render={(text, { excellent }) => (!excellent ? '-' : excellent)}
      />
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Good %</span>}
        align="center"
        dataIndex="good"
        render={(text, { good }) => (!good ? '-' : good)}
      />
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Poor %</span>}
        align="center"
        dataIndex="poor"
        render={(text, { poor }) => (!poor ? '-' : poor)}
      />
      <Table.Column<SessionFlyCountDistributionSummary>
        title={<span className="boldTitle">Critical %</span>}
        align="center"
        dataIndex="critical"
        render={(text, { critical }) => (!critical ? '-' : critical)}
      />
    </Table>
  </div>
);

export default SessionFlyCountDistributionSummaryTable;
