import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

const FarmsScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // selectedIndex is used for the active tab
  let selectedIndex = 0;

  // if selected tab has 'pastures' route, then store tab index
  if (location.pathname.includes('pastures')) {
    selectedIndex = 1;
  }

  return (
    <div className="tabContainer">
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(tabIndex: number) => {
          if (tabIndex === 1) {
            navigate('pastures');
          } else {
            navigate('');
          }
        }}
      >
        <TabList style={{ padding: '0px 35px', fontSize: '16px' }}>
          <Tab>View All Producers</Tab>
          <Tab>Pastures</Tab>
        </TabList>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default FarmsScreen;
