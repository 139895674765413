import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './LoginScreen.module.scss';
import { logger } from '../utils/helpers';
import useAuth from '../utils/auth/useAuth';
import { ReactComponent as Logo } from '../assets/icons/Purina_Fly_Monitor_Logo_White.svg';

/* React functional component */
const LoginScreen: React.FC = () => {
  /* State to decide whether to show loader on sign in btn */
  const [signInLoading, setSignInLoading] = useState<boolean>(false);

  const { login } = useAuth();

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerRedContainer}>
        <div>
          <Logo width={300} />
        </div>
        <div className="producerBtnStyleContainer">
          {/* Sign in with SSO button */}
          <Button
            style={{
              paddingLeft: 60,
              paddingRight: 60,
              marginTop: 20,
              marginBottom: 20,
            }}
            size="large"
            onClick={() => {
              setSignInLoading(true);
              login().catch((error) => {
                setSignInLoading(false);
                logger(error as Error);
              });
            }}
            loading={signInLoading}
          >
            Sign in with SSO
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
