import React from 'react';
import { ReactComponent as AlertGold } from '../assets/icons/Alert_Gold.svg';
import colors from '../utils/variables.module.scss';

const AddImageScreen: React.FC = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // set this because parent container min height is set to calc(100vh - 170px);
      height: 'calc(100vh - 170px)',
    }}
  >
    <AlertGold style={{ width: 90, height: 90, marginBottom: 50 }} />
    <p style={{ fontSize: 16, fontFamily: 'HelveticaBold' }}>
      To enable offline usage of mobile app, this feature has been removed from web application.
      Please use the mobile app to capture new images or add existing images from gallery.
    </p>
    <p style={{ fontFamily: 'HelveticaBold', fontSize: 16, color: colors.yellowColor }}>
      You can use the web app to view Producer related information, view analysis data and generate
      reports.
    </p>
  </div>
);
export default AddImageScreen;
