import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import styles from './WarningMessageComponent.module.scss';

// type definition for props used in this component
type WarningMessageComponentProps = {
  // indicates message to be displayed
  message: string | JSX.Element;
};

const WarningMessageComponent: React.FC<WarningMessageComponentProps> = (props) => {
  const { message } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <span className={styles.warningText}>
        <WarningFilled className={styles.warningIcon} />
        {message}
      </span>
    </div>
  );
};

export default WarningMessageComponent;
