import React from 'react';
import {
  CheckboxGroupProps as AntCheckboxGroupProps,
  CheckboxValueType,
} from 'antd/es/checkbox/Group';
import { Checkbox } from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';

// component props
export interface CheckboxGroupProps
  extends Omit<AntCheckboxGroupProps, 'name' | 'onChange' | 'value'> {
  // react hook form identifier
  name: string;
  // controller props
  rhfControllerProps: Omit<UseControllerProps<any>, 'name'>;
  // onChange handler
  onChange?: (e: CheckboxValueType[]) => void;
}

// default props
const optionalPropsValues = {
  onChange: undefined,
};

// component
const CheckboxGroup = ({
  rhfControllerProps,
  onChange,
  name,
  ...checkboxGroupProps
}: CheckboxGroupProps): JSX.Element => {
  // accessing react hook form hook
  const {
    field: { onChange: rhfOnChange, ...rhfFields },
  } = useController({
    name,
    ...rhfControllerProps,
  });

  return (
    <Checkbox.Group
      onChange={(e) => {
        // if custom onChange callback is invoked
        if (onChange) {
          onChange(e);
        }
        rhfOnChange(e);
      }}
      {...checkboxGroupProps}
      {...rhfFields}
    />
  );
};

CheckboxGroup.defaultProps = optionalPropsValues;

export default CheckboxGroup;
