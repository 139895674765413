import React from 'react';
import InfoText from '../components/InfoText';

const HelpScreen = () => (
  <div style={{ padding: 20, marginLeft: 30 }}>
    <InfoText>
      For any questions or feedback, please email purinaflymonitor@landolakes.com or call
      800.227.8941
    </InfoText>
  </div>
);

export default HelpScreen;
