import React from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { ChartType, SessionFlyCountDistributionSummary } from '../utils/types';
import styles from './Charts.module.scss';
import { renderFlyCountDistributionChartBars, renderYAxisLabel } from '../utils/helpers';
import { xAxisLabelCommonProps } from '../utils/globals';
import colors from '../utils/variables.module.scss';

// component prop type def
type SessionFlyCountDistributionChartProps = {
  // responsive width to take fully flex the width of screen
  responsiveWidth?: string | number;
  // bar chart data
  barData: SessionFlyCountDistributionSummary[];
  // bar type to know which type of graph to show on screen it can be simple graph or stack graph
  chartType: ChartType;
};

const SessionFlyCountDistributionChart: React.FC<SessionFlyCountDistributionChartProps> = ({
  responsiveWidth = 620,
  barData,
  chartType,
}) => {
  // const to store updated barData where good, excellent, poor, critical are converted into percentage value
  // filtering data so that if any entry with all property has undefined value then that data will not be in graphData
  const graphData = barData.filter(
    ({ excellent, good, poor, critical }) => excellent || good || poor || critical,
  );

  return (
    <div
      className={responsiveWidth === '100%' ? '' : styles.chartWrapper}
      style={{ padding: 20, marginBottom: 20 }}
    >
      <div className={styles.flexCenter}>
        <ResponsiveContainer width={responsiveWidth} height={400}>
          <BarChart data={graphData} width={620} height={400}>
            <CartesianGrid stroke="#333" strokeWidth={0.2} vertical={false} />
            <XAxis
              padding={{ left: 20, right: 20 }}
              axisLine={{ stroke: colors.greyColor }}
              tickCount={5}
              interval={0}
              label={{
                value: 'Session Start Date',
                ...xAxisLabelCommonProps,
              }}
              dataKey="startDate"
              type="category"
              tickFormatter={(val: string) => dayjs(val).format('MMM DD, YY')}
              tick={
                barData.length === 0
                  ? false
                  : {
                      fontSize: 13,
                    }
              }
            />
            <YAxis
              axisLine={{ stroke: colors.greyColor }}
              interval={0}
              domain={[0, 100]}
              allowDataOverflow
              label={renderYAxisLabel({
                x: 35,
                y: 208,
                angle: -90,
                dy: -1.2,
                label: '% Images in Category',
              })}
              tick={
                barData.length === 0
                  ? false
                  : {
                      fontSize: 13,
                    }
              }
            />
            <Legend
              verticalAlign="bottom"
              align="left"
              iconSize={12}
              wrapperStyle={{
                position: 'relative',
                marginLeft: 30,
              }}
              formatter={(value) => (
                <span
                  style={{
                    textTransform: 'capitalize',
                    marginRight: 20,
                    fontWeight: 500,
                  }}
                >
                  {value}
                </span>
              )}
            />
            <Tooltip
              cursor={barData.length > 0}
              wrapperStyle={{ zIndex: 10 }}
              labelFormatter={(val: string) => dayjs(val).format('MMM DD, YY')}
              formatter={(value: number, name: string) => {
                // logic to capitalize the name
                const formattedLabel = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
                return [`${value}%`, formattedLabel];
              }}
              labelStyle={{ fontWeight: 'bold', color: colors.blackColor }}
            />
            {renderFlyCountDistributionChartBars(chartType)}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SessionFlyCountDistributionChart;
