import React from 'react';
import colors from '../utils/variables.module.scss';

const Footer: React.FC = () => (
  <div
    style={{
      height: 50,
      padding: 20,
      marginTop: 20,
      backgroundColor: colors.blackColor,
      color: colors.whiteColor,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    }}
  >
    © 2022 Purina Animal Nutrition LLC. All Rights Reserved. | Privacy & Terms
  </div>
);

export default Footer;
