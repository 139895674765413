import React from 'react';
import {
  LineChart,
  ReferenceArea,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { PastureDataForSessionsChart } from '../utils/types';
import styles from './Charts.module.scss';
import { renderYAxisLabel } from '../utils/helpers';
import { referencePlotData, xAxisLabelCommonProps, lineChartGraphColors } from '../utils/globals';
import colors from '../utils/variables.module.scss';

// component type def
type PastureSessionFlyCountChartProps = {
  // data for all selected pastures
  sessionLineChartData: PastureDataForSessionsChart[];
  // responsive width to take fully flex the width of screen
  responsiveWidth?: string | number;
  // using id, name of each selected pasture to render legend and plot session wise line data points
  selectedPastureDetails: Record<'id' | 'name', string>[];
};

// renders reference shading areas for reference lines
export const renderRefArea = (): JSX.Element[] =>
  referencePlotData.map(({ min, max, fill }) => (
    <ReferenceArea fill={fill} strokeWidth={0} y1={min} y2={max} alwaysShow />
  ));

// component
const PastureSessionFlyCountChart: React.FC<PastureSessionFlyCountChartProps> = ({
  sessionLineChartData,
  responsiveWidth = 620,
  selectedPastureDetails,
}) => {
  // function to find largest data point to set as Y-axis domain max range
  const findMaxDataPointVal = (): number => {
    // storing all available y-axis data points
    const dataPoints: number[] = [];
    sessionLineChartData.forEach((item) => {
      // storing data points for each item
      const flyCountValues = Object.values(item).slice(2, 3) as number[];
      dataPoints.push(...flyCountValues);
    });
    return dataPoints.sort((a, b) => a - b)[dataPoints.length - 1] + 50;
  };

  return (
    <div
      className={responsiveWidth === '100%' ? '' : styles.chartWrapper}
      style={{ padding: '10px 20px 20px 20px' }}
    >
      <div className={styles.flexCenter}>
        <ResponsiveContainer width={responsiveWidth} height={400}>
          <LineChart data={sessionLineChartData} width={620} height={400}>
            <CartesianGrid stroke="#333" strokeWidth={0.2} vertical={false} />
            <XAxis
              allowDuplicatedCategory={false}
              padding={{ left: 20, right: 20 }}
              axisLine={{ stroke: colors.greyColor }}
              tickCount={5}
              interval={0}
              label={{
                value: 'Session Start Date',
                ...xAxisLabelCommonProps,
              }}
              dataKey="x"
              type="category"
              tickFormatter={(val: string) => dayjs(val).format('MMM DD, YY')}
              tick={
                sessionLineChartData.length === 0
                  ? false
                  : {
                      fontSize: 13,
                    }
              }
            />
            <YAxis
              axisLine={{ stroke: colors.greyColor }}
              interval={sessionLineChartData.length === 0 ? 'preserveEnd' : 0}
              domain={[0, findMaxDataPointVal()]}
              label={renderYAxisLabel({
                x: 35,
                y: 208,
                angle: -90,
                dy: -1.2,
                label: 'Average Fly Count',
              })}
              dataKey="y"
            />
            {sessionLineChartData.length > 0 ? (
              <Legend
                verticalAlign="bottom"
                align="left"
                iconSize={24}
                wrapperStyle={{
                  position: 'relative',
                  marginRight: 30,
                  marginBottom: 20,
                }}
                payload={selectedPastureDetails.map(({ id, name }, index) => ({
                  value: (
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                        marginRight: 10,
                        color: colors.blackColor,
                      }}
                    >
                      {name}
                    </span>
                  ),
                  id,
                  type: 'line',
                  color: lineChartGraphColors[index],
                  payload: { strokeDasharray: 0 },
                }))}
                formatter={(value) => (
                  <span style={{ marginRight: 20, fontWeight: 500 }}>{value}</span>
                )}
              />
            ) : undefined}
            {selectedPastureDetails.map(({ id, name }, index) => (
              <Line
                key={id}
                connectNulls
                stroke={lineChartGraphColors[index]}
                dataKey={name}
                strokeWidth={1.5}
                isAnimationActive={false}
                dot={{
                  strokeWidth: 0.8,
                  fill: lineChartGraphColors[index],
                  r: 2.6,
                }}
              />
            ))}
            <Tooltip
              cursor={sessionLineChartData.length > 0}
              labelFormatter={(sessionDate: number) => (
                <span>{dayjs(sessionDate).format('MMM DD, YYYY')}</span>
              )}
              formatter={(value: number) => [value.toFixed(0), 'Average Fly Count']}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PastureSessionFlyCountChart;
