import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import FarmsScreen from './screens/FarmsScreen';
import AllFarmsScreen from './screens/AllFarmsScreen';
import PasturesScreen from './screens/PasturesScreen';
import UsageStatisticsScreen from './screens/UsageStatisticsScreen';
import AddImageScreen from './screens/AddImageScreen';
import NewAnalysisScreen from './screens/NewAnalysisScreen';
import Footer from './components/Footer';
import HelpScreen from './screens/HelpScreen';

// This is the main functional component
const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <Header />
    <div className="ScreenContainer">
      <Routes>
        <Route path="/addImage" element={<AddImageScreen />} />
        <Route path="/analysis" element={<NewAnalysisScreen />} />
        <Route path="/producers/*" element={<FarmsScreen />}>
          <Route path="/" element={<AllFarmsScreen />} />
          <Route path="/pastures" element={<PasturesScreen />} />
        </Route>
        <Route path="/users/usage" element={<UsageStatisticsScreen />} />
        <Route path="/help" element={<HelpScreen />} />
        <Route path="/" element={<Navigate to="/analysis" />} />
      </Routes>
    </div>
    <Footer />
  </BrowserRouter>
);

export default AppRoutes;
