import colors from './variables.module.scss';
// generating random colors for all fly count charts
export const chartColorSet = [
  '#FF6619',
  '#dffce3',
  '#ffece8',
  '#f7ebff',
  '#f0f0f0',
  '#1075e6',
  '#8C030E',
  '#66bb6a',
  '#cd9b01',
  '#4C1F7C',
  '#338a3e',
  '#f44336',
  '#03a9f4',
  '#ff9800',
  '#c51162',
  '#3f51b5',
  '#29b6f6',
  '#812F33',
  '#00e676',
  '#e57373',
  '#677E52',
  '#aeea00',
  '#795548',
  '#1dbd7c',
];

// const use to store colors of bar and stacked graphs
export const barChartGraphColors = [
  colors.excellentColor,
  colors.goodColor,
  colors.poorColor,
  colors.criticalColor,
];

// graph colors used in line chart
export const lineChartGraphColors = [
  '#00b327',
  '#0565ff',
  '#ff1e35',
  '#6a00ba',
  '#ad1599',
  '#ff6600',
  '#78290f',
  '#264653',
  '#fca311',
  '#685044',
];

// reference line and reference area plot data
// export const referencePlotData = [
//   {
//     name: 'Excellent',
//     min: 0,
//     max: 250,
//     fill: chartColorSet[1],
//   },
//   {
//     name: 'Good',
//     min: 250,
//     max: 500,
//     fill: chartColorSet[2],
//   },
//   {
//     name: 'Poor',
//     min: 500,
//     max: 750,
//     fill: chartColorSet[3],
//   },
//   {
//     name: 'Critical',
//     min: 750,
//     max: 1000,
//     fill: chartColorSet[4],
//   },
// ];

// reference line and reference area plot data
export const referencePlotData = [
  {
    name: 'Excellent',
    min: 0,
    max: 50,
    fill: chartColorSet[1],
  },
  {
    name: 'Good',
    min: 50,
    max: 200,
    fill: chartColorSet[2],
  },
  {
    name: 'Poor',
    min: 200,
    max: 500,
    fill: chartColorSet[3],
  },
  {
    name: 'Critical',
    min: 500,
    max: 1000,
    fill: chartColorSet[4],
  },
];

// chart common xAxis label common props
export const xAxisLabelCommonProps = {
  position: 'bottom',
  style: { fontWeight: 500, fontSize: 16, color: colors.greyColor },
  offset: 5,
};

export default null;
