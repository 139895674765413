import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Table, Drawer } from 'antd';
import { loader } from 'graphql.macro';
import { AllFarmsQuery } from '../graphql/graphql-types';
import FarmDetailComponent from '../components/FarmDetailsComponent';
import { FarmData } from '../utils/types';
import WarningMessageComponent from '../components/WarningMessageComponent';
import colors from '../utils/variables.module.scss';
import InfoText from '../components/InfoText';

/* load all farms query */
const allFarmsQuery = loader('../graphql/queries/allFarmsQuery.graphql');

// This is the Main functional component
const AllFarmsScreen: React.FC = () => {
  // This is the state in which we store whether to show farm detail drawer or not
  const [showDetailDrawer, setShowDetailDrawer] = useState<boolean>(false);

  // It has the data for farm detail drawer
  const [farmDataToShow, setFarmDataToShow] = useState<FarmData | null>(null);

  // This is the detail of the farm fetched from the graphql
  const { data, error, loading } = useQuery<AllFarmsQuery, null>(allFarmsQuery);

  // farms store the value of all the farms coming from graphql
  const farms = data?.farm;

  // error while be displayed if any while fetching data
  if (error) {
    return <div className="errorMessage">{error.message}</div>;
  }

  return (
    <div style={{ padding: '0px 55px', marginTop: 30 }}>
      <InfoText>
        Please use the Fly Monitor mobile app to add new producer, update or delete existing
        producer.
      </InfoText>

      <Drawer
        title={<h2 style={{ fontFamily: 'HelveticaBold', marginBottom: 0 }}>Producer Profile</h2>}
        placement="right"
        closable
        onClose={() => {
          setFarmDataToShow(null);
          setShowDetailDrawer(false);
        }}
        visible={showDetailDrawer}
        width={450}
        className="drawerContainer"
      >
        {farmDataToShow !== null && showDetailDrawer ? (
          <FarmDetailComponent farmDataToShow={farmDataToShow} />
        ) : null}
      </Drawer>

      <div className="producerTable">
        {Array.isArray(farms) && farms.length > 0 ? (
          <Table<FarmData>
            dataSource={farms}
            bordered={false}
            pagination={{
              position: ['bottomRight'],
              pageSize: 10,
              showSizeChanger: false,
              size: 'default',
            }}
            style={{ padding: '20px 0px' }}
            size="middle"
            loading={loading}
            rowKey="id"
          >
            <Table.Column<FarmData>
              key="name"
              title="Ranch Name"
              dataIndex="name"
              render={(text, record) => (
                <Link
                  style={{
                    fontFamily: 'HelveticaBold',
                    color: colors.blueColor,
                    marginBottom: 0,
                  }}
                  to=""
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    setFarmDataToShow(record);
                    setShowDetailDrawer(true);
                  }}
                >
                  {text}
                </Link>
              )}
            />

            <Table.Column<FarmData>
              title="Manager Name"
              dataIndex="manager_name"
              key="manager_name"
              render={(text, record): string => {
                if (record.manager_name) {
                  return record.manager_name;
                }
                return '-';
              }}
            />
            <Table.Column<FarmData>
              title="Manager Email"
              dataIndex="manager_email"
              key="manager_email"
              render={(text, record): string => {
                if (record.manager_email) {
                  return record.manager_email;
                }
                return '-';
              }}
            />
            <Table.Column<FarmData>
              title="Manage Phone"
              dataIndex="manager_phone"
              key="manager_phone"
              align="center"
              render={(text, record): string => {
                if (record.manager_phone) {
                  return record.manager_phone;
                }
                return '-';
              }}
            />
          </Table>
        ) : (
          <div style={{ marginTop: 30 }}>
            <WarningMessageComponent message=" Please add a producer using the mobile app" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllFarmsScreen;
