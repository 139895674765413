import React, { useState } from 'react';
import { Button, Descriptions, message, Modal } from 'antd';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import styles from '../screens/PastureScreen.module.scss';
import InputComponent from '../components/InputComponent';
import { logger } from '../utils/helpers';
import {
  Session,
  UpdateSessionByPkMutation,
  UpdateSessionByPkMutationVariables,
} from '../graphql/graphql-types';
import { AllSessionsDataForPastureType, SessionsDataType } from '../utils/types';
import InfoText from '../components/InfoText';

// type definition for session comments form modal component prop
type SessionCommentsModalPropType = {
  // prop use to check whether modal is open or close
  modalVisibility: boolean;
  // prop use to set the visibility of modal
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  // prop used to store selected session id
  sessionId: string;
  // prop used to know from which screen modal is called
  calledFrom: 'analysisScreen' | 'pastureScreen' | 'addImageScreen' | 'reportPage';
  // prop use only when modal is called from add image screen to get initial comment of selected session
  initialComment?: string;
  // prop used only when modal called from analysis screen it used to set all pastures sessions data
  // so that when user get latest value when he reopen modal for same session.
  setAllSessionsDataOfPasture?: React.Dispatch<
    React.SetStateAction<AllSessionsDataForPastureType[]>
  >;
  // prop use to pass basic details of session when called from analysis or pasture screen.
  sessionDetails?: Pick<Session, 'pasture_session_name' | 'start_date' | 'comment'> & {
    pastureName: string | undefined;
    pastureId: string;
  };
  // indicates info message to be displayed
  infoMessage?: string;
};

// type definition for session form
type SessionCommentsFormType = {
  // text area field to enter session comments
  sessionComments: string;
};

// loading updateSessionByPk mutation to update newly added / edited comment
const updateSessionByPkMutation = loader('../graphql/mutations/updateSessionByPkMutation.graphql');

// react functional component
const SessionCommentsFormModal: React.FC<SessionCommentsModalPropType> = ({
  modalVisibility,
  setModalVisibility,
  sessionId,
  calledFrom,
  initialComment = undefined,
  setAllSessionsDataOfPasture = undefined,
  sessionDetails = undefined,
  infoMessage = '',
}) => {
  // extracting useForm
  const { control, handleSubmit, reset } = useForm<SessionCommentsFormType>({
    defaultValues: {
      sessionComments:
        calledFrom !== 'addImageScreen' && sessionDetails && sessionDetails.comment
          ? sessionDetails.comment
          : initialComment,
    },
  });

  // state use to set loading indicator on save button
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState<boolean>(false);

  // extracting updateSessionByPk mutation using useMutation
  const [updateSessionByPk] = useMutation<
    UpdateSessionByPkMutation,
    UpdateSessionByPkMutationVariables
  >(updateSessionByPkMutation);

  // function will be called when user submit the form by clicking save button
  const onSubmit = ({ sessionComments }: SessionCommentsFormType) => {
    setIsSaveBtnLoading(true);
    // call to updateSessionByPk mutation
    updateSessionByPk({
      variables: {
        session_id: sessionId,
        comment: sessionComments,
      },
    })
      .then(() => {
        setIsSaveBtnLoading(false);

        // below logic will called only when modal called from analysis screen
        if (setAllSessionsDataOfPasture && sessionDetails) {
          setAllSessionsDataOfPasture((prevData) => {
            const selectedPastureCompleteData = prevData.find(
              (ele) => ele.id === sessionDetails.pastureId,
            );
            // variable to store updated session with recent comment
            let updatedSessionData: SessionsDataType[];
            if (selectedPastureCompleteData) {
              updatedSessionData = selectedPastureCompleteData.sessions.map((ele) => {
                if (ele.id === sessionId) {
                  return { ...ele, comment: sessionComments };
                }
                return ele;
              });
            }

            // returning map is imp as sometime table does not consider data changed on setState as using spread operate does not change ref of array
            return prevData.map((ele) => {
              if (ele.id !== sessionDetails.pastureId) {
                return ele;
              }
              return { ...ele, sessions: updatedSessionData };
            });
          });
        }
        setModalVisibility(false);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Farm call comments have been successfully updated.');
      })
      .catch((err) => {
        logger(err);
        reset();
      });
  };

  return (
    <Modal visible={modalVisibility} destroyOnClose footer={null} closable={false}>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div style={{ padding: '10px 20px 10px 20px' }}>
          <h2 style={{ fontFamily: 'HelveticaBold', paddingLeft: 10, paddingTop: 20 }}>
            Farm Call Comments
          </h2>
          {calledFrom !== 'addImageScreen' && sessionDetails ? (
            <Descriptions labelStyle={{ width: 100, fontWeight: 700, marginLeft: 10 }} column={1}>
              <Descriptions.Item label="Pasture">
                {sessionDetails.pastureName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Farm call">
                {sessionDetails.pasture_session_name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Start date">
                {dayjs(sessionDetails.start_date).format('MMM DD, YYYY') || '-'}
              </Descriptions.Item>
            </Descriptions>
          ) : null}
          {calledFrom === 'reportPage' ? (
            <InputComponent
              placeholder="Please enter your comments for this farm call"
              name="sessionComments"
              hasTextArea
              rhfControllerProps={{ control }}
              customStyles={{ width: '100%', marginTop: 10 }}
              textAreaProps={{ rows: 5 }}
            />
          ) : (
            <InfoText>
              <span style={{ flex: 1 }}>{infoMessage}</span>
            </InfoText>
          )}
        </div>
        <p style={{ marginLeft: 18 }}>{sessionDetails ? sessionDetails.comment : ''}</p>
        <div
          style={{ display: 'flex', justifyContent: 'end', paddingRight: 25, paddingBottom: 20 }}
        >
          <Button
            onClick={() => {
              setModalVisibility(false);
              reset();
            }}
            type="link"
            className="formCancelBtn"
          >
            <p>{calledFrom === 'reportPage' ? 'Cancel' : 'Close'}</p>
          </Button>
          {calledFrom === 'reportPage' ? (
            <Button htmlType="submit" className="formSubmitBtn" loading={isSaveBtnLoading}>
              Save
            </Button>
          ) : null}
        </div>
      </form>
    </Modal>
  );
};

export default SessionCommentsFormModal;
