import React from 'react';
import { ApolloError } from '@apollo/client';
import { Tag } from 'antd';
import { Bar, Legend } from 'recharts';
import { barChartGraphColors, referencePlotData } from './globals';
import { ChartType } from './types';
import colors from './variables.module.scss';

// This is a function by which we get the object of particular key
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

// used to log errors
export const logger = (error: Error | ApolloError): void => {
  console.log('message', error.message);
  console.log('error name', error.name);
  // If ApolloError
  if ((error as ApolloError).graphQLErrors) {
    const apolloError = error as ApolloError;
    console.log('ApolloError.extraInfo', apolloError.extraInfo);
    console.log('ApolloError.graphQLErrors', apolloError.graphQLErrors);
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
  }
};

// This addressFormat func is used for showing address in required form in farm section
export const addressFormat = (addInfo: {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string;
}): string => {
  const address = ['addressLine1', 'addressLine2', 'city', 'state', 'zip'];
  const addressSend: string[] = [];
  address.forEach((add) => {
    if (addInfo && add && hasKey(addInfo, add)) {
      const addr: string | null = addInfo[add];
      if (addr) {
        addressSend.push(addr);
      }
    }
  });
  return addressSend.join(', ');
};

// This function is used for finding average fly count category category
export const calcAvgFlyCountCategory = (count: number): string => {
  if (count <= 50) {
    return 'Excellent';
  }
  if (count > 50 && count <= 200) {
    return 'Good';
  }
  if (count > 200 && count <= 500) {
    return 'Poor';
  }
  return 'Critical';
};

// function to render x-axis and y-axis ticks
export const renderTicks = ({
  start,
  range,
  counter,
}: Record<'start' | 'range' | 'counter', number>): number[] => {
  // storing ticks data
  const ticks = [start];
  // iterating until input range and increasing counter
  for (let i = 0; i < range; i++) {
    ticks.push(ticks[i] + counter);
  }
  return ticks;
};

// function to render Y-axis label using svg <text> element
export const renderYAxisLabel = ({
  x,
  y,
  angle = 90,
  dy,
  label,
}: Record<'x' | 'y' | 'angle' | 'dy', number> & {
  // y axis label
  label: string;
}): JSX.Element => (
  <text
    offset={5}
    x={x}
    y={y}
    className="recharts-text recharts-label"
    textAnchor="middle"
    transform={`rotate(${angle}, ${x}, ${y})`}
    style={{ fontWeight: 500, fontSize: 16, color: colors.greyColor }}
  >
    <tspan x={x} dy={`${dy}em`}>
      {label}
    </tspan>
  </text>
);

// rendering custom ReferenceArea legend
export const renderRefAreaLegend = ({ marginTop }: { marginTop: number }): JSX.Element => (
  <div style={{ position: 'relative', margin: `${marginTop}px 0 0 50px` }} id="customRefAreaLegend">
    <Legend
      style={{ position: 'relative', marginBottom: 0, paddingBottom: 50 }}
      payload={[
        {
          name: 'Average Fly Count Category Legend -',
          fill: '#000',
        },
        ...referencePlotData,
      ].map(({ name, fill }) => ({
        id: name,
        value: <span style={{ color: '#000' }}>{name}</span>,
        type: 'rect',
        color: fill,
        strokeColor: '#000',
        stroke: 'red',
        strokeWidth: 2,
        strokeDasharray: '2',
      }))}
      align="left"
    />
  </div>
);

// renders average fly count chart data legend
export const renderAvgFlyCountLegend = (): JSX.Element => (
  <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: 20, marginTop: 10 }}>
    {['0-50: Excellent', '50-200: Good', '200-500: Poor', '>500: Critical'].map((val) => (
      <span key={val} style={{ marginLeft: 20, fontWeight: 'bold', color: colors.blackColor }}>
        {val}
      </span>
    ))}
  </div>
);

// fly count distribution image categories
export const renderFlyCountDistributionChartBars = (chartType: ChartType): JSX.Element[] =>
  ['excellent', 'good', 'poor', 'critical'].map((key, index) => {
    // if  condition to render correct type of bar based on chartType value
    if (chartType === 'stackBar') {
      return (
        <Bar
          key={key}
          isAnimationActive={false}
          barSize={50}
          dataKey={key}
          fill={barChartGraphColors[index]}
          stackId="y"
        />
      );
    }
    return (
      <Bar
        key={key}
        barSize={50}
        isAnimationActive={false}
        dataKey={key}
        fill={barChartGraphColors[index]}
      />
    );
  });

// function used to get good, excellent, poor, critical category percentage based on total accepted image
export const getPercentValueOfFlyCountCategory = (
  categoryCount: number | null | undefined,
  totalAcceptedImgCount: number | null | undefined,
): number | undefined => {
  if (categoryCount && totalAcceptedImgCount) {
    return Math.round((categoryCount / totalAcceptedImgCount) * 100);
  }
  return undefined;
};

// This function is used for finding average fly count category category
export const calcAvgFlyCountCategoryChip = (count: number): JSX.Element => {
  // variable use to store tag text based on condition
  let categoryText = '';
  // variable use to store tag color based on condition
  let categoryColor = '';
  if (count <= 50) {
    categoryText = 'Excellent';
    categoryColor = colors.excellentColor;
  }
  if (count > 50 && count <= 200) {
    categoryText = 'Good';
    categoryColor = colors.goodColor;
  }
  if (count > 200 && count <= 500) {
    categoryText = 'Poor';
    categoryColor = colors.poorColor;
  }
  if (count > 500) {
    categoryText = 'Critical';
    categoryColor = colors.criticalColor;
  }
  return (
    <Tag
      style={{
        borderRadius: 12,
        fontFamily: 'HelveticaBold',
        width: 75,
      }}
      color={categoryColor}
    >
      <p style={{ marginBottom: 0, fontSize: 12, marginTop: 2 }}>{categoryText}</p>
    </Tag>
  );
};

export default null;
