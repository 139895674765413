/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { ApolloError, useMutation } from '@apollo/client';
import { Control, UseFormWatch, UseFormSetValue, Controller, useFieldArray } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { EyeOutlined } from '@ant-design/icons';
import {
  Maybe,
  UpdateSessionByPkMutation,
  UpdateSessionByPkMutationVariables,
} from '../graphql/graphql-types';
import {
  AllSessionsDataForPastureType,
  GenerateReportForm,
  SessionsDataType,
} from '../utils/types';
import { calcAvgFlyCountCategoryChip, logger } from '../utils/helpers';
import ImageCardModalAndLightbox from '../components/ImageCardModalAndLightbox';
import SessionCommentsFormModal from '../forms/SessionCommentsFormModal';
import colors from '../utils/variables.module.scss';
import Checkbox from '../components/Checkbox';
import InputComponent from '../components/InputComponent';
import { ReactComponent as AlertYellow } from '../assets/icons/Alert_Yellow.svg';
import { ReactComponent as Exclamation } from '../assets/icons/Exclamation_Octogon_Red.svg';
import { ReactComponent as CommentIcon } from '../assets/icons/Comment_Bubble.svg';

// new session wise summary table prop type
type NewSessionWiseSummaryTablePropType = {
  // prop use to store data that will be pass to table
  sessionWiseSummaryTableData: Array<SessionsDataType>;
  // setState function to update all pastures sessions data
  setAllSessionsDataOfPasture: React.Dispatch<
    React.SetStateAction<AllSessionsDataForPastureType[]>
  >;
  // selected pastures fetched session data error state
  dataError?: ApolloError;
  // selected pastures fetched session data loading state
  dataLoading: boolean;
  // whether to show/hide analysis report
  showAnalysisReport?: boolean;
  // whether to show/hide all excluded session entries
  hideExcludedSessionData?: boolean;
  // sessionWiseSummaryTableForm control
  control?: Control<GenerateReportForm>;
  // generate report form watch instance
  watch?: UseFormWatch<GenerateReportForm>;
  // generate report form set value instance
  setValue?: UseFormSetValue<GenerateReportForm>;
  // prop used to send useField array index so that you can access its child array
  nestedIndex?: number;
};

// loading updateSessionByPk mutation to update newly added / edited comment
const updateSessionByPkMutation = loader('../graphql/mutations/updateSessionByPkMutation.graphql');

const NewSessionWiseSummaryTable: React.FC<NewSessionWiseSummaryTablePropType> = ({
  sessionWiseSummaryTableData,
  setAllSessionsDataOfPasture,
  dataLoading,
  dataError = undefined,
  showAnalysisReport = false,
  hideExcludedSessionData = false,
  control = undefined,
  watch = undefined,
  setValue = undefined,
  nestedIndex = undefined,
}) => {
  // state to set the visibility of session comments form modal
  const [isSessionCommentsModalVisible, setIsSessionCommentsModalVisible] = useState<boolean>(
    false,
  );

  // state use to set session id of rows to know whose view image button is clicked and which is also used to show image lightbox component
  const [viewImagesForSessionId, setViewImagesForSessionId] = useState<string | null>(null);

  // state use to set session object of session whose comment button is clicked
  const [
    selectedSessionForComment,
    setSelectedSessionForComment,
  ] = useState<SessionsDataType | null>(null);

  // state use to set session id of rows to determine which input is currently focused
  const [focusedInputSessionId, setFocusedInputSessionId] = useState<string | null>(null);

  // nested use fields array to handle comment and comment include values of respective session
  const { fields: nestedFields } = useFieldArray({
    control,
    name: `sessionWiseSummaryTableForm[${
      nestedIndex as number
    }].sessions` as 'sessionWiseSummaryTableForm.0.sessions',
  });

  // const used to store watch data of sessionWiseSummaryTableForm
  const formData = watch ? watch('sessionWiseSummaryTableForm') : undefined;

  // useEffect logic to set include all sessions comment checkbox if all individual comment check
  useEffect(() => {
    if (formData && setValue) {
      const result: Array<boolean> = [];
      formData.forEach((ele) => {
        if (Array.isArray(ele.sessions)) {
          result.push(ele.sessions.every((item) => item.commentToInclude === true));
        }
      });
      if (result.every((value) => value === true)) {
        setValue('includeAllSessionsComment', true);
      } else {
        setValue('includeAllSessionsComment', false);
      }
    }
  }, [formData, setValue]);

  // extracting updateSessionByPk mutation using useMutation
  const [updateSessionByPk] = useMutation<
    UpdateSessionByPkMutation,
    UpdateSessionByPkMutationVariables
  >(updateSessionByPkMutation);

  if (dataError) {
    return <div style={{ textAlign: 'center' }}>{dataError.message}</div>;
  }

  return (
    <>
      <div className="sectionHeading analysisTable" style={{ padding: '5px 20px 20px 20px' }}>
        <Table
          pagination={false}
          loading={dataLoading}
          dataSource={
            !showAnalysisReport
              ? sessionWiseSummaryTableData
              : sessionWiseSummaryTableData.filter((ele) => ele.rowAction !== 'exclude')
          }
          rowKey="id"
          size="small"
          rowClassName={(record) => (record.rowAction === 'exclude' ? 'selectionRow' : '')}
        >
          <Table.Column<SessionsDataType>
            title={<span className="boldTitle"> Farm Call Start Date</span>}
            key="start_date"
            dataIndex="start_date"
            render={(text, { start_date }) => dayjs(start_date).format('MM/DD/YYYY')}
          />
          <Table.Column<SessionsDataType>
            title={<span className="boldTitle">Accepted Images</span>}
            dataIndex={['session_all_images', 'aggregate', 'count']}
            render={(text, { session_accepted_agg: { aggregate } }) =>
              aggregate && aggregate.count ? aggregate.count : '-'
            }
            align="center"
          />
          <Table.Column<SessionsDataType>
            title={
              <span className="boldTitle">
                Rejected <br />
                Images
              </span>
            }
            dataIndex={['session_rejected_images', 'aggregate', 'count']}
            render={(text, { session_rejected_images: { aggregate } }) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
              aggregate && aggregate.count ? aggregate.count : '-'
            }
            align="center"
          />
          <Table.Column<SessionsDataType>
            title={
              <span className="boldTitle">
                Estimated Average <br />
                Fly Count
              </span>
            }
            key="avgFlyCount"
            render={(
              text,
              {
                session_all_images: { aggregate: totalImgAggregate },
                session_unprocessed_images: { aggregate: unprocessedImgAggregate },
              },
            ) => {
              // average fly count per session
              const avgFlyCount =
                totalImgAggregate &&
                totalImgAggregate.avg &&
                (totalImgAggregate.avg.fly_count_thresh1 ||
                  totalImgAggregate.avg.fly_count_thresh1 === 0)
                  ? Math.round(totalImgAggregate.avg.fly_count_thresh1)
                  : '-';
              // unprocessed img count
              const unprocessedImgCount = unprocessedImgAggregate?.count;

              return unprocessedImgCount && unprocessedImgCount > 0 ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {avgFlyCount}
                  <Tooltip
                    title={`${unprocessedImgCount} ${
                      unprocessedImgCount === 1 ? 'image is' : 'images are'
                    } under analysis`}
                  >
                    <AlertYellow style={{ width: 15, height: 15, marginLeft: 10 }} />
                  </Tooltip>
                </div>
              ) : (
                avgFlyCount
              );
            }}
            align="center"
          />
          <Table.Column<SessionsDataType>
            title={
              <span className="boldTitle">
                Average Fly Count
                <br /> Category
              </span>
            }
            key="avgFlyCountCategory"
            render={(text, { session_all_images: { aggregate } }) =>
              aggregate &&
              aggregate.avg &&
              (aggregate.avg.fly_count_thresh1 || aggregate.avg.fly_count_thresh1 === 0) ? (
                calcAvgFlyCountCategoryChip(aggregate.avg.fly_count_thresh1)
              ) : (
                <p style={{ padding: 0, margin: '0px 8px 0px 0px' }}>-</p>
              )
            }
            align="center"
          />
          {!showAnalysisReport ? (
            <Table.Column<SessionsDataType>
              title={<span style={{ marginLeft: 170 }}>Actions</span>}
              align="center"
              render={(text, record) =>
                record.pasture_session_name === '' ? null : (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div
                      className="producerBtnStyleContainer"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        onClick={() => {
                          setIsSessionCommentsModalVisible(true);
                          setSelectedSessionForComment(record);
                        }}
                        icon={<EyeOutlined />}
                      >
                        Comments
                      </Button>
                      <Button
                        onClick={() => {
                          setViewImagesForSessionId(record.id);
                        }}
                        icon={<CommentIcon style={{ marginRight: 5, width: 20, height: 20 }} />}
                        style={{ marginRight: 10, marginLeft: 10 }}
                      >
                        View Images
                      </Button>
                    </div>
                    <div
                      className={record.rowAction === 'include' ? 'producerBtnStyleContainer' : ''}
                    >
                      <Button
                        onClick={() => {
                          setAllSessionsDataOfPasture((prevState) => {
                            // const to store  selected pasture data
                            const selectedSessionPasture = prevState.find(
                              (item) => item.id === record.pastureId,
                            );
                            // const use to store updated session data with updated value of rowAction
                            let updatedSessionData: SessionsDataType[];
                            if (selectedSessionPasture) {
                              updatedSessionData = selectedSessionPasture.sessions.map((item) => {
                                if (item.id === record.id) {
                                  return {
                                    ...item,
                                    rowAction:
                                      record.rowAction === 'include' ? 'exclude' : 'include',
                                  };
                                }
                                return item;
                              });
                            }

                            return prevState.map((pasture) => {
                              if (pasture.id === record.pastureId) {
                                return { ...pasture, sessions: updatedSessionData };
                              }
                              return pasture;
                            });
                          });
                        }}
                      >
                        {record.rowAction === 'include' ? 'Exclude' : 'Include'}
                      </Button>
                    </div>
                  </div>
                )
              }
            />
          ) : null}

          {showAnalysisReport && nestedIndex !== undefined ? (
            <Table.Column<SessionsDataType>
              title={<span>Comments</span>}
              dataIndex="comment"
              width={200}
              render={(text, record, index) => {
                // if condition to change focused input to input textArea so that multiline comment is visible
                if (record.id === focusedInputSessionId) {
                  return (
                    <Controller
                      control={control}
                      name={
                        `sessionWiseSummaryTableForm.${nestedIndex}.sessions.${index}.comment` as const
                      }
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input.TextArea
                          autoSize
                          style={{
                            width: '100%',
                          }}
                          // autoFocus to resolve double click issue
                          autoFocus={focusedInputSessionId === record.id}
                          key={nestedFields[index].id}
                          value={value}
                          onChange={onChange}
                          onFocus={(e) => {
                            setFocusedInputSessionId(record.id);
                            const inputValue = value ? (value as string) : '';
                            // code to set cursor at end of the text
                            e.currentTarget.setSelectionRange(inputValue.length, inputValue.length);
                          }}
                          onBlur={(e) => {
                            onBlur();
                            setFocusedInputSessionId(null);
                            // mutation to update comment in session table
                            updateSessionByPk({
                              variables: {
                                session_id: record.id,
                                comment: e.target.value,
                              },
                            }).catch((err) => {
                              logger(err);
                            });
                          }}
                        />
                      )}
                    />
                  );
                }

                return (
                  <Controller
                    control={control}
                    name={
                      `sessionWiseSummaryTableForm.${nestedIndex}.sessions.${index}.comment` as const
                    }
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        style={{
                          width: '100%',
                        }}
                        key={nestedFields[index].id}
                        onChange={onChange}
                        value={value}
                        onFocus={() => {
                          setFocusedInputSessionId(record.id);
                        }}
                        onBlur={() => {
                          onBlur();
                          setFocusedInputSessionId(null);
                        }}
                      />
                    )}
                  />
                );
              }}
            />
          ) : null}
          {showAnalysisReport && nestedIndex !== undefined ? (
            <Table.Column<SessionsDataType>
              dataIndex="commentCheck"
              render={(text, record, index) => (
                <>
                  <Checkbox
                    rhfControllerProps={{ control }}
                    name={
                      `sessionWiseSummaryTableForm.${nestedIndex}.sessions.${index}.commentToInclude` as const
                    }
                  />
                  {/* this input is just to store sessionId of rows  so that we can identify element of sessionWiseSummaryTableForm uniquely and hence it display property is set to none */}
                  <InputComponent
                    placeholder="session id"
                    rhfControllerProps={{ control }}
                    customStyles={{ display: 'none' }}
                    name={
                      `sessionWiseSummaryTableForm.${nestedIndex}.sessions.${index}.sessionId` as const
                    }
                  />
                </>
              )}
            />
          ) : null}
        </Table>
      </div>
      {isSessionCommentsModalVisible && selectedSessionForComment ? (
        <SessionCommentsFormModal
          modalVisibility={isSessionCommentsModalVisible}
          setModalVisibility={setIsSessionCommentsModalVisible}
          calledFrom="analysisScreen"
          setAllSessionsDataOfPasture={setAllSessionsDataOfPasture}
          sessionId={selectedSessionForComment.id}
          sessionDetails={{
            pasture_session_name: selectedSessionForComment.pasture_session_name,
            start_date: selectedSessionForComment.start_date,
            comment: selectedSessionForComment.comment,
            // const to store pasture info to get pasture name as we want name of pasture to show in modal
            //  but as we store empty string as pasture name for all entries except first entry we need this logic
            pastureName: selectedSessionForComment.pastureName,
            pastureId: selectedSessionForComment.pastureId,
          }}
          infoMessage="Comments for a specific report can be updated while generating report on next page. Please use mobile app for permanently updating comments."
        />
      ) : null}
      {viewImagesForSessionId ? (
        <ImageCardModalAndLightbox
          sessionId={viewImagesForSessionId}
          setImageModalVisibility={setViewImagesForSessionId}
        />
      ) : null}
    </>
  );
};

export default NewSessionWiseSummaryTable;
