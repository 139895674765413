import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// component props
export interface CheckboxProps extends Omit<AntCheckboxProps, 'name' | 'onChange' | 'value'> {
  // react hook form identifier
  name: string;
  // controller props
  rhfControllerProps: Omit<UseControllerProps<any>, 'name'>;
  // onChange handler
  onChange?: (e: CheckboxChangeEvent) => void;
}

// default prop values
const optionalPropsValues = {
  onChange: undefined,
};

// component
const Checkbox = ({
  onChange,
  rhfControllerProps,
  name,
  ...checkboxProps
}: CheckboxProps): JSX.Element => {
  // accessing react hook form hook
  const {
    field: { onChange: rhfOnChange, ...rhfFields },
  } = useController({
    name,
    ...rhfControllerProps,
  });

  return (
    <AntCheckbox
      onChange={(e: CheckboxChangeEvent) => {
        if (onChange) {
          onChange(e);
        }
        rhfOnChange(e.target.checked);
      }}
      {...checkboxProps}
      {...rhfFields}
      checked={rhfFields.value as boolean}
    />
  );
};

Checkbox.defaultProps = optionalPropsValues;

export default Checkbox;
